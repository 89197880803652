import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import API_URL from './ApiData'
import { useHistory, NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import copy from "copy-to-clipboard"
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Modal from 'react-bootstrap/Modal';
const MultiMedia = () => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageList, setPageList] = useState([1,2,3]);
    const [lastPage, setLastPage] = useState(10);
    const [nextBtnStatus, setNextBtnStatus]=useState(false)
    const [prevBtnStatus, setPrevBtnStatus]=useState(false)
    const inputRef = useRef(null)
    const [markStatus, setMarkStatus]=useState(false)
    const history = useHistory()
    const swiperRef = useRef(null);

    const handleChecked = (event) => {
      setMarkStatus(event.target.checked);
    }
    const getCurrentTimeInIST = () => {
        const now = new Date();
        // Calculate IST by adding 5 hours and 30 minutes (in milliseconds)
        const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
        // Format the date to 'YYYY-MM-DD HH:mm:ss'
        const formattedDate = istTime.toISOString().replace('T', ' ').substring(0, 19);
        return `${formattedDate} +05:30`; // Append the IST offset
    };
    const handleNextPage = () => {
        if(nextBtnStatus){
          setCurrentPage(currentPage + 1);
          let newArry = pageList;
          let newAddArray = pageList[pageList.length-1]+1;
          // if(currentPage > 2){
            let newRemoveArray = pageList[0];
          //
          // }
          newArry.shift(newRemoveArray);
          newArry.push(newAddArray);
            setPageList(newArry);
            }
      }
        const [popData, setPopData] =useState()
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = (data) => {
            setShow(true)
            setPopData(data)
            console.log('vikasP', popData)
        }
          
         
      const handlePrevPage = () => {
      if(currentPage > 1){
        setCurrentPage(currentPage - 1);
        // setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1];
        let newRemoveArray = pageList[0]-1;
          newArry.unshift(newRemoveArray);
        newArry.pop(newAddArray);
      setPageList(newArry);
      }
      }
    
    
      const handleStartPage = () => {
      // if(requestFlag){
        setCurrentPage(1);
        let lastArrtty = [1, 2, 3]
        setPageList(lastArrtty);
      //  }
      }
    const handleChange = event=>{
        const {name, value} =event.target;
        setInputSData({ ...inputSData, [name]: value })
        setCurrentPage(1)
    
      }
      const [inputSData, setInputSData] = useState({
        title: ''
      })
    const fetchData = (page, ln)=>{
        setLoading(true);
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
        };

        // Fetch data from the API when the component mounts
        let apiUrl = `https://stingers.ianswire.in/api/videos/?page=${currentPage}&departments=2&query=`
        if(inputSData.title){
            apiUrl = `${apiUrl}${inputSData.title}`
          }
        axios.get(apiUrl, { headers })
            .then(response => {
                setNewsData(response.data.results);
                if(response.data.next){
                    setNextBtnStatus(true)
                }else{
                    setNextBtnStatus(false)
                }
                if(response.data.previous){
                    setPrevBtnStatus(true)
                }else{
                    setPrevBtnStatus(false)
                }
                setLastPage(response.data.count)
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }
    const handleRemoveImpLink = ()=>{

            axios({
              method: "get",
              url: `https://stingers.ianswire.in/api/reset_mark_important/`,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken :''}`,
                
              },
            
            })
              .then((res) => {
                fetchData(currentPage, localStorage.langg);
              })
              .catch((error) => {
              
                console.error("Error uploading image: ", error);
              });
    }
    const regex = /(<([^>]+)>)/gi;
    const [isAccept, setIsAccept] = useState(false)
    const [isload, setIsload] = useState(false)
    const [imgArry, setImgAry] = useState([]);
    const handleSubmit =  (type) => {
        // e.preventDefault();
        setIsload(true)
         if(!popData.title){
           alert('Title can not be blank.');
           setIsload(false)
           return false;
         }else if(!popData.description){
           alert('Description can not be blank.');
           setIsload(false)
           return false;
         }
     const NewAPArry = imgArry.map(video => video.id);
     
         let tempdata = {
           id: popData.id,
           title: popData.title,
           description: popData.description,
           video_ids: NewAPArry,
           videos:imgArry,
           event_type:popData.event_type,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: type== 'hold' ? 'on_hold' : type== 'pending' ? 'pending': type== 'submitted' ? 'submitted' :'',
           language: popData.language,
           mark_important:popData.mark_important,
           remarks: popData.remarks,
           slug:popData.slug,
           updated_at: getCurrentTimeInIST(),
         }
         let tempAccptdata = {
           id: popData.id,
           title: popData.title,
           description: popData.description,
           video_ids: NewAPArry,
           videos:imgArry,
           event_type:popData.event_type,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: 'approved',
           language: popData.language,
           accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
           remarks: popData.remarks,
           mark_important:popData.mark_important,
           slug:popData.slug,
           updated_at: getCurrentTimeInIST(),
         }
         let tempRejectdata = {
           id: popData.id,
           title: popData.title,
           description: popData.description,
           video_ids: NewAPArry,
           videos:imgArry,
           event_type:popData.event_type,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: 'rejected',
           mark_important:popData.mark_important,
           language: popData.language,
           slug:popData.slug,
           accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
           remarks: popData.remarks,
           updated_at: getCurrentTimeInIST(),
         }  
         axios({
           method: "put",
           url: `${API_URL.CREATE_VIDEO}${popData.id}/`,
           data: type && type == 'approved' ? tempAccptdata : type == 2 ? tempRejectdata : tempdata,
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
           },
         }).then((response) => {
           if (response.status === 200) {
             console.log('Success:', response.data);
             setIsload(false)
             setShow(false)
             fetchData(currentPage, localStorage.langg);

            //  if(localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='All content' && type !='pending'){
            //      history.push('/all-video-stn'); // Redirect to the home page on success
            //  }else{
            //    if(type !='pending'){
            //      history.push('/my-videos'); // Redirect to the home page on success
            //    }
     
            //  }
           // history.push('/all-video-stn'); // Redirect to the home page on success
           }
         })
       }
       const [reFresh, setReFresh] = useState(false)
       const copyToText = async () => {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = popData.description
     
        // Remove most HTML tags except for <p>
        let copyt = ''
     
        // Replace specific <p> tags with double line breaks
        //copy = copy.replace(/<p><\/p>/g, '\n\n');
     
        // Keep specific <p> tags at the bottom
        const specificTags = tempDiv.querySelectorAll('p')
        specificTags.forEach((tag) => {
           copyt += '\n\n' + tag.innerHTML;
        });
     
        // Combine title, short_desc, and modified content
        let final_copy = '';
        //if(final_copy){
           final_copy = `${popData.title}\n\n ${copyt}`
           final_copy=final_copy.replace("<br>","\n")
           final_copy=final_copy.replace('<br>','\n')
           final_copy = final_copy.replace(/&nbsp;/g, ' ')
           final_copy=final_copy.replace('<br data-mce-bogus="1">','')
           copy(final_copy);
           //handleSubmit(popData.title, popData.id)
        //}
        // Copy the final content to the clipboard
        //await navigator.clipboard.writeText(final_copy);
        }
        const handleMouseEnter = () => {
            if (swiperRef.current && swiperRef.current.swiper) {
              swiperRef.current.swiper.autoplay.stop();
            }
          };
        
          const handleMouseLeave = () => {
            if (swiperRef.current && swiperRef.current.swiper) {
              swiperRef.current.swiper.autoplay.start();
            }
          };
        const downloadToText = async () => {
           const tempDiv = document.createElement('div');
           tempDiv.innerHTML = popData.description;
     
           // Remove most HTML tags except for <p>
           let copyt = '';
     
           // Replace specific <p> tags with double line breaks
           //copy = copy.replace(/<p><\/p>/g, '\n\n');
     
           // Keep specific <p> tags at the bottom
           const specificTags = tempDiv.querySelectorAll('p');
           specificTags.forEach((tag) => {
              copyt += '\n\n' + tag.innerHTML;
           });
     
           // Combine title, short_desc, and modified content
           let final_copy = `${popData.title}\n\n ${copyt}`;
           final_copy=final_copy.replace('<br>','\n')
           final_copy = final_copy.replace(/&nbsp;/g, ' ')
           final_copy=final_copy.replace('<br data-mce-bogus="1">','')
           // Copy the final content to the clipboard
           copy(final_copy);
           const filename = popData.id+".txt"; // Replace with the desired file name
     
           const blob = new Blob([final_copy], { type: 'text/plain' });
           const url = URL.createObjectURL(blob);
     
           const a = document.createElement('a');
           a.href = url;
           a.download = filename;
     
           document.body.appendChild(a);
           a.click();
     
           window.URL.revokeObjectURL(url);
           document.body.removeChild(a);
          // handleSubmit(collectionData.title, collectionData.id)
        }
        const editRedir=(link)=>{
          history.push(link);
        }       
    useEffect(() => {
        // Set up headers
        if(localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg != 'All content'){
          //  history.push('/my-videos');
        }
        if(localStorage && localStorage.length > 0 && localStorage.publishOpt && localStorage.publishOpt == 'publish'){
            setIsAccept(true)
          }
          setShow(false)
        fetchData(currentPage, localStorage.langg);

    }, [currentPage]);
    return (
        <>
    <div className="wrapper">
        <Header />
            <div className="content-wrapper">
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                        
                            <div className="row">
                            {  !loading && 
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                        <div className="row stn-search-hed">
                                            <div className="col-lg-6 d-flex align-items-center ev-stn">
                                                <h5 className="card-title"><span>All Videos</span> <img className="nav-i-icon" src="/assets/icons/stn-all-video-b.png"/></h5>
                                            </div>
                                            
                                            <div className="col-lg-4">
                                                <div className="search-form-allsnt">
                                                    <form onSubmit={()=>{fetchData(1,'')}}>
                                                        <input type="search" ref={inputRef} placeholder="Search...." name="title" value={inputSData.title} onChange={handleChange}/>
                                                        <span className="input-group-text border-0" onClick={()=>{
                                                fetchData(1,'')
                                                }}><i className="fas fa-search" aria-hidden="true"></i></span>
                                                    </form>
                                                </div>
                                            </div>
                                            {  localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='All content' &&
                                            <div className="col-lg-2 align-end">
                                                <div className="search-form-allsnt">
                                                <button className="btn btn-reset-mark" type="button" onClick={handleRemoveImpLink}>Reset Important <i className="fas fa-undo"></i></button>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div id="no-more-tables">                                        
                                        <table className="col-md-12 table table-bordered table-condensed cf">
                                                <thead className="cf">
                                                    <tr>
                                                        <th>ID</th>
                                                        <th className="col-md-5">Heading</th>
                                                        <th className="numeric">Created by</th>
                                                        <th className="numeric">Approved by</th>
                                                        {/* <th className="numeric">Accepted by</th> */}
                                                        <th className="numeric">Created & Updated at</th>
                                                        <th className="numeric">Status</th>
                                                        <th className="numeric">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newsData.map((newsItem) => (
                                                        <tr key={newsItem.id} onClick={()=>editRedir('/edit-stn-video/' + newsItem.id)
                                                      } className={newsItem.mark_important ? 'trmark-imp' : ''}>
                                                          
                                                            <td data-title="ID">{newsItem.id}</td>
                                                            <td data-title="Heading"><strong>{newsItem.title}</strong>
                                                            <p>File Path: <br />Z:/{newsItem.slug}</p>
                                                            </td>
                                                            <td data-title="Created by" className="numeric">{newsItem.created_by}</td>
                                                            
                                                            {/* <td data-title="Approved by" className="numeric">{newsItem.accepted_by}</td> */}
                                                            <td data-title="Accepted by" className="numeric">{newsItem.accepted_by}</td>
                                                            <td data-title="Created & Updated at" className="numeric">
                                                                <div>
                                                                    <strong>Created at:</strong> {new Date(newsItem.created_at).toLocaleString()}
                                                                </div>
                                                                <div>
                                                                    <strong>Updated at:</strong> {new Date(newsItem.updated_at).toLocaleString()}
                                                                </div>
                                                            </td>
                                                            <td data-title="Status" className="numeric">
                                                                {newsItem.status ? (
                                                                    <span className={`badge ${getStatusClass(newsItem.status)}`}>
                                                                        {newsItem.status =='on_hold' ? 'hold': newsItem.status}
                                                                        {newsItem.mark_important ? (
                                                                            <div className="spinner-grow text-danger" role="status">
                                                                                <span className="visually-hidden">Loading...</span>
                                                                            </div>
                                                                        ) : ''}
                                                                    </span>
                                                                ) : ''}
                                                            </td>
                                                            <td data-title="Action" className="numeric">
                                                                <span className="btn btn-sm btn-primary mx-1">
                                                                    <i className="fas fa-edit viewedit" aria-hidden="true"></i>
                                                                </span>
                                                            </td>
                                                           
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {/* Pagination (You can customize pagination functionality here) */}
                                            <div className="pagination">
                                                <ul>
                                                    {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                                                    {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                                                    {pageList.map((data, i)=>{
                                                    return(
                                                    <li key={i} onClick={()=>{
                                                    setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                                        )
                                                    })}
                                                    <li onClick={handleNextPage}><a href="#" className="prevnext">Next</a></li>
                                                </ul>                                            							
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            }
                                {loading && 
                                <div className="card-body">
                                    <div className="load-img">
                                        Loading...
                                        <div className="loader"></div>
                                    </div>  
                                </div>  
                            }
                            </div>
                        </div>

                        <>
                        <div className='news-r-pop'>
                        <Modal id='news-r-pop'
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='news-r-cont'>

                                    <h4>{popData && popData.title ? popData.title: ''}</h4>
                                    <div className="share-social-icons">
                                 <ul>
                                    {/* <li className="downloadhbtn">
                                       <a alt="Download file" href="#" title="Download file" onClick={downloadToText}>
                                          <span>Download image</span>
                                          <i alt="Download file" className="fas fa-download"> </i>
                                       </a>
                                    </li> */}
                                    <li className="downloadhbtn">
                                       <a href="#" title="Copy Text" onClick={copyToText}>
                                       <span>Copy TXT</span>
                                          <i className="fas fa-copy" id="copyicon"></i>
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                                   {popData && popData.videos && popData.videos.length == 1 && 
                                    
                                    <video controls autoplay loop controlslist="" src={popData.videos[0].video  ? popData.videos[0].video : ''}>
                                        <source type="video/mp4" src={popData.videos[0].video  ? popData.videos[0].video : ''}/>
                                    </video>
                                    }
                                     {popData && popData.videos && popData.videos.length > 1 &&
                                            <section id="testimonials" className="testimonials" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <div className="main-heading">
                                              {/* <h2 className="heading">{popData && popData.title ? popData.title : ''}</h2> */}
                                            </div>
                                            <Swiper
                                              ref={swiperRef}
                                              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                              spaceBetween={30}
                                              slidesPerView={1}
                                              autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                              }}
                                              navigation
                                              breakpoints={{
                                                320: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 10,
                                                },
                                                480: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 20,
                                                },
                                                668: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 30,
                                                },
                                                900: {
                                                  slidesPerView: 1,
                                                  spaceBetween: 30,
                                                },
                                              }}
                                            >
                                              <div className="clients-slider swiper four-col-category-list" data-aos="fade-up" data-aos-delay="100">
                                                <div className="swiper-wrapper four-col-category-list">
                                                  {popData.videos.map((deep, d) => {
                                                    return (
                                                      <SwiperSlide key={d}>
                                                        <div className="testimonial-item four-col-category-list">
                                                          <figure>
                                                           
                                                            <div className="img-area-four-col-category-list">
                                                              <NavLink to={'#'}>
                                                                <video controls autoplay loop controlslist="" src={deep.video}>
                                                                    <source type="video/mp4" src={deep.video}/>
                                                                </video>
                                                                <p className="videogcounter"> {d+1} <span> / {popData.videos.length}</span></p>
                                                              </NavLink>

                                                             
                                                            </div>
                                                          </figure>
                                                        </div>
                                                      </SwiperSlide>
                                                    )
                                                  })}
                                                </div>
                                                <div className="swiper-pagination"></div>
                                              </div>
                                            </Swiper>
                                          </section>
                                     } 
                                    {   popData && popData.description  && 
                                        <div className='r-dic' dangerouslySetInnerHTML={{ __html:  popData.description.substr(0,300) }}/>
                                        // <div dangerouslySetInnerHTML={{ __html:  popData.description.replace(regex, "").substr(0,300) }}/>

                                    }
                                    <div className='r-popdate'>   
                                        {popData && popData.created_at && 
                                            <span>
                                                <strong>Created at:</strong> {new Date(popData.created_at).toLocaleString()}
                                            </span>
                                        }
                                        { popData && popData.updated_at && 
                                            <span>
                                                <strong>Updated at:</strong> {new Date(popData.updated_at).toLocaleString()}
                                            </span>
                                        } 
                                        <div className="col-md-12 text-left mt-4 pub-btn-fixedd">
                                        
                                        {  localStorage && localStorage.length > 0 && isAccept &&     

                                        <button className="btn btn-success" type="button" onClick={() => handleSubmit('approved')}>Approve</button>
                                        }
                                        {  localStorage && localStorage.length > 0 && isAccept && 
                                        
                                        <button className="btn btn-warning" type="button" onClick={() => handleSubmit('hold')}>Hold</button>

                                        }                    
                                        {  localStorage && localStorage.length > 0 && isAccept &&     
                                        <button className="btn btn-danger" type="button" onClick={() => handleSubmit(2)}>Reject</button>
                                        } 
                                    </div>  
                                    </div>
             
                                    {/* {popData && popData.status && popData.status =='approved' &&
                                    <span className={ 'badge bg-success'}>{popData.status.toUpperCase()}</span>
                                    }
                                    
                                    {popData && popData.status && popData.status =='on_hold' &&
                                    <span className={ 'badge bg-warning'}>{popData.status.toUpperCase()}</span>
                                    }
                                    
                                    {popData && popData.status && popData.status =='rejected' &&
                                    <span className={ 'badge bg-danger'}>{popData.status.toUpperCase()}</span>
                                    }

                                    {popData && popData.status && popData.status =='submitted' &&
                                    <span className={ 'badge pending_snt'}>{popData.status.toUpperCase()}</span>
                                    }                                     */}
                                </div>
                            </Modal.Body>
                        </Modal>
                        </div>
                        </>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
        </>
    );
};

// Helper function to determine badge color based on status
const getStatusClass = (status) => {
    switch (status) {
        case 'approved':
            return 'bg-success';
        case 'on_hold':
            return 'bg-warning';
        case 'rejected':
            return 'bg-danger';
        default:
            return 'pending_snt';
    }
};
const handleView = (id) => {
    // Replace this with actual view functionality
    console.log(`Viewing news item with ID: ${id}`);
};

// Function to handle edit button click
const handleEdit = (id) => {
    // Replace this with actual edit functionality
    console.log(`Editing news item with ID: ${id}`);
};

export default MultiMedia;
