import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory, NavLink } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library
import API_URL from './ApiData'
import {useParams} from 'react-router-dom';
import copy from "copy-to-clipboard"
import { format, parseISO } from 'date-fns'
const EditStnVideo = () => {
  const history = useHistory()
  const [isAccept, setIsAccept] = useState(false)
  const {editId} = useParams();
  const handleChange = event=>{
    const {name, value} =event.target;
    if(name && name=='slug'){
      if (value.length > 50) {
        alert("You can only enter up to 50 characters!");
        let tempValue= value.substr(0,50);
        //return false;
        setInputData({ ...inputData, [name]: tempValue });
      }else{
        setInputData({ ...inputData, [name]: value });
      }
    //  alert(value)
    }else{
      setInputData({ ...inputData, [name]: value });
    }
  }
  const handleDeptChange = event=>{
    const {name, value} =event.target;

      setInputData({ ...inputData, [name]: value });
   
  }
  const getCurrentTimeInIST = () => {
    const now = new Date();
    // Calculate IST by adding 5 hours and 30 minutes (in milliseconds)
    const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
    // Format the date to 'YYYY-MM-DD HH:mm:ss'
    const formattedDate = istTime.toISOString().replace('T', ' ').substring(0, 19);
    return `${formattedDate} +05:30`; // Append the IST offset
};
  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  }
  const [readOn, setReadOn] = useState(false)
  const [isVideoRow, setIsVideoRow] = useState(false)
  const [conData, setValue] = useState('');
  const [markStatus, setMarkStatus]=useState(false)
   const [departData, setdepartData] = useState();
   const departmentsData = [
    { id: 1, slug: "social-media", short_name: "SM", name: "Social Media" },
    { id: 2, slug: "Multimedia", short_name: "MM", name: "Multimedia" },
    { id: 3, slug: "hindi-desk", short_name: "HIN", name: "Hindi Desk" },
    { id: 4, slug: "english-desk", short_name: "ENG", name: "English Desk" },
    { id: 5, slug: "business", short_name: "BUS", name: "Business" },
    { id: 6, slug: "entertainment", short_name: "ENT", name: "Entertainment" },
    { id: 7, slug: "sports", short_name: "SPO", name: "Sports" },
    { id: 8, slug: "science-tech", short_name: "ST", name: "Science & Tech" },
  ];
// const defaultCheck = [    {id:1,
//   slug:'social-media',
//   name:'Social Media',
//   status:true,
//   description:'Social Media department'
// },
// {
//   id:2,
//   slug:'Multimedia',
//   name:'Multimedia',
//   status:false,
//   description:'Multimedia department'
// },
// {
//   id:3,
//   slug:'hindi-desk',
//   name:'Hindi Desk',
//   status:false,
//   description:'Hindi Desk'
// },
// {
//   id:4,
//   slug:'english-desk',
//   name:'English Desk',
//   status:false,
//   description:'English Desk'
// }];
const defaultCheck = [1,2,3,4];
  const handleChecked = (event) => {
    setMarkStatus(event.target.checked);
  };
  const [inputData, setInputData] = useState({
    id: null,
    title: '',
    description: '',
    videos: [],
    stringers:'',
    status: "",
    slug:"",
    language: '',
    created_by: "",
    accepted_by: "",
    remarks: "",
    event_type:"",
    mark_important:false,
    created_at: ""
  })
  const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
let remarT = [ { Name: "SM", status: false, username: "vikas"}, { Name: "MM", status: false, username: "vikas" }, { Name: "HIN", status: false, username: "vikas" }, { Name: "ENG", status: false, username: "vikas" }];
  const fetchData = async () => {
    setIsload(true) 
      axios(
         {method: "get",
          url: `${API_URL.CREATE_VIDEO}${editId}/`,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
          },
        
        })
      .then((res) => {
          if(res && res.data){
            setInputData({
              title: res.data.title,
              description: res.data.description,
              status: res.data.status,
              // language: res.data.language,
              created_by: res.data.created_by,
              stringers:res.data.stringers && res.data.stringers.length > 0 && res.data.stringers[0].id ? res.data.stringers[0].id:[],
              accepted_by:res.data.accepted_by,
              remarks: res.data.remarks,
              slug: res.data.slug,
              event_type:res.data.event_type,
              videos:res.data.videos,
              marks:res.data.marks,
              BUS:res.data.BUS,
              ENG:res.data.ENG,
              ENT:res.data.ENT,
              HIN:res.data.HIN,
              MM:res.data.MM,
              SM:res.data.SM,
              SPO:res.data.SPO,
              ST:res.data.ST,
              created_at: res.data.created_at ? formatDate(res.data.created_at): '',
            })
            if(res.data.mark_important){
              setMarkStatus(true)
          }
          if(res && res.data && res.data.departments && res.data.departments.length > 0){
          
            let departmentIds = res.data.departments; // [3, 5]

            // Filter departmentsData to match only those with the given IDs
            const filteredDepartments = departmentsData.filter((dept) =>
              departmentIds.includes(dept.id)
            );
            setdepartData(filteredDepartments);
            setSelectedDepartments(res.data.departments); 
          }else{

            setSelectedDepartments(defaultCheck); 
          }
         
            setValue(res.data.description)
            if(res.data.videos && res.data.videos.length > 0){
              setImgAry(res.data.videos)
            }
            // if(res.data.created_by && localStorage && localStorage.length > 0 && localStorage.userName && res.data.created_by != localStorage.userName){
            //   setIsAccept(true)
            // }
            if(res.data.remarks){
              setRemoveCont(res.data.remarks)
            }
            setIsload(false)
          }
      })
      //setIsload(false)
  }


  // Usage



  const handleCheckboxChange = (id) => {
    setSelectedDepartments((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((depId) => depId !== id) // Remove if exists
        : [...prevSelected, id] // Add if not exists
    );
  };
  const handleSubmit =  (type) => {
   // e.preventDefault();
   setIsload(true)
    if(!inputData.title){
      alert('Title can not be blank.');
      setIsload(false)
      return false;
    }else if(!conData){
      alert('Description can not be blank.');
      setIsload(false)
      return false;
    }else if(!selectedDepartments || selectedDepartments.length == 0){
      alert('Department can not be blank.');
      setIsload(false)
      return false;
    }
const NewAPArry = imgArry.map(video => video.id);
let NewArry = inputData.stringers && inputData.stringers.length > 0 ? [parseInt(inputData.stringers)]: [];

    let departIds = departData && departData.length > 0 && departData.map(item => item.id); 
    let tempdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      video_ids: NewAPArry,
      videos:imgArry,
      event_type:inputData.event_type,
      stringers: inputData.stringers && inputData.stringers.length > 0 ? [parseInt(inputData.stringers)]: [],
      stringer_ids: NewArry,
      status: type== 'hold' ? 'on_hold' : type== 'pending' ? 'pending': type== 'submitted' ? 'submitted' :'',
      // language: inputData.language,
      // created_by: inputData.created_by,
      // accepted_by: inputData.accepted_by,
      BUS:localStorage && localStorage.langg && localStorage.langg == 'BUS' ? localStorage.langg : inputData.BUS,
      ENG:localStorage && localStorage.langg && localStorage.langg == 'ENG' ? localStorage.langg : inputData.ENG,
      ENT:localStorage && localStorage.langg && localStorage.langg == 'ENT' ? localStorage.langg : inputData.ENT,
      HIN:localStorage && localStorage.langg && localStorage.langg == 'HIN' ? localStorage.langg : inputData.HIN,
      MM:localStorage && localStorage.langg && localStorage.langg == 'MM' ? localStorage.langg : inputData.MM,
      SM:localStorage && localStorage.langg && localStorage.langg == 'SM' ? localStorage.langg : inputData.SM,
      SPO:localStorage && localStorage.langg && localStorage.langg == 'SPO' ? localStorage.langg : inputData.SPO,
      ST:localStorage && localStorage.langg && localStorage.langg == 'ST' ? localStorage.langg : inputData.ST,
      mark_important:markStatus,
      remarks: inputData.remarks,
      slug:inputData.slug,
      departments:selectedDepartments,
      updated_at: getCurrentTimeInIST(),
    }
    let tempAccptdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      video_ids: NewAPArry,
      videos:imgArry,
      event_type:inputData.event_type,
      stringers: inputData.stringers && inputData.stringers.length > 0 ? [parseInt(inputData.stringers)]: [],
      stringer_ids: NewArry,
      status: 'approved',
      // language: inputData.language,
      // created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: inputData.remarks,
      mark_important:markStatus,
      BUS:localStorage && localStorage.langg && localStorage.langg == 'BUS' ? localStorage.langg : inputData.BUS,
      ENG:localStorage && localStorage.langg && localStorage.langg == 'ENG' ? localStorage.langg : inputData.ENG,
      ENT:localStorage && localStorage.langg && localStorage.langg == 'ENT' ? localStorage.langg : inputData.ENT,
      HIN:localStorage && localStorage.langg && localStorage.langg == 'HIN' ? localStorage.langg : inputData.HIN,
      MM:localStorage && localStorage.langg && localStorage.langg == 'MM' ? localStorage.langg : inputData.MM,
      SM:localStorage && localStorage.langg && localStorage.langg == 'SM' ? localStorage.langg : inputData.SM,
      SPO:localStorage && localStorage.langg && localStorage.langg == 'SPO' ? localStorage.langg : inputData.SPO,
      ST:localStorage && localStorage.langg && localStorage.langg == 'ST' ? localStorage.langg : inputData.ST,
      slug:inputData.slug,
      departments:selectedDepartments,
      updated_at: getCurrentTimeInIST(),
    }
    let tempRejectdata = {
      id: inputData.id,
      title: inputData.title,
      description: conData,
      video_ids: NewAPArry,
      videos:imgArry,
      event_type:inputData.event_type,
      stringers: inputData.stringers && inputData.stringers.length > 0 ? [parseInt(inputData.stringers)]: [],
      stringer_ids: NewArry,
      status: 'rejected',
      mark_important:markStatus,
      BUS:localStorage && localStorage.langg && localStorage.langg == 'BUS' ? localStorage.langg : inputData.BUS,
      ENG:localStorage && localStorage.langg && localStorage.langg == 'ENG' ? localStorage.langg : inputData.ENG,
      ENT:localStorage && localStorage.langg && localStorage.langg == 'ENT' ? localStorage.langg : inputData.ENT,
      HIN:localStorage && localStorage.langg && localStorage.langg == 'HIN' ? localStorage.langg : inputData.HIN,
      MM:localStorage && localStorage.langg && localStorage.langg == 'MM' ? localStorage.langg : inputData.MM,
      SM:localStorage && localStorage.langg && localStorage.langg == 'SM' ? localStorage.langg : inputData.SM,
      SPO:localStorage && localStorage.langg && localStorage.langg == 'SPO' ? localStorage.langg : inputData.SPO,
      ST:localStorage && localStorage.langg && localStorage.langg == 'ST' ? localStorage.langg : inputData.ST,
      // language: inputData.language,
      slug:inputData.slug,
      departments:selectedDepartments,
      // created_by: inputData.created_by,
      accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
      remarks: inputData.remarks,
      updated_at: getCurrentTimeInIST(),
    }  
    axios({
      method: "put",
      url: `${API_URL.CREATE_VIDEO}${editId}/`,
      data: type && type == 'approved' ? tempAccptdata : type == 2 ? tempRejectdata : tempdata,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        console.log('Success:', response.data);
        setIsload(false)

        if(localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='All content' && type !='pending'){
            history.push('/all-video-stn'); // Redirect to the home page on success
        }else{
          if(type !='pending'){
            if(localStorage.langg=='SM'){
              history.push('/socialmedia'); // Redirect to the home page on success
            }else{
              history.push('/my-videos'); // Redirect to the home page on success
            }
            
          }
        }
      // history.push('/all-video-stn'); // Redirect to the home page on success
      }
    })
  }
  const emailAfter =(ty)=>{
    setIsload(true)
    handleSubmit(ty)
  }
const [strinData, setStringData] = useState()
const [optionsTY, setOptionsTY] =useState()
const inputSData=(event)=>{
  event.preventDefault()
  setSearch(event.target.value);
} 
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)

    const getStringerData = () =>{
            let imSearchAPI = `https://stingers.ianswire.in/api/stringers/?stringer_type=video_service`;
            if(inputSearch){
                imSearchAPI = imSearchAPI+`&search=${inputSearch}`
            }
            axios.get(
            imSearchAPI,
            {
                headers: {
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                },
            }
            ).then((res) => {
            const allStrngData =
                res.data.results &&
                res.data.results.length > 0 &&
                res.data.results.map((item) => {
                return {
                account_no: item.account_no,
                district : item.district,
                email : item.email,
                id : parseInt(item.id),
                ifsc_code : item.ifsc_code,
                name: item.name,
                number : item.number,
                state: item.state,
                uniqueID : item.uniqueID
                    
                };
                });
            if(res && res.data.results && res.data.results && res.data.results.length > 0 ){
            setStringData(allStrngData);
            const optionsvvTY = allStrngData.map(person => ({
                value: person.id,
                label: person.name
              }));
              setOptionsTY(optionsvvTY);
            // console.log('vbb',imgSearchD);
            }else{
              setStringData()
            }
            });
        if(!inputSearch){
          fetchData()
        }
    }
  const [isload, setIsload] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [msg, setMsg]= useState('')
  const [imgArry, setImgAry] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    if(e && e.target && e.target.files[0]){
      if(e.target.files[0].type != "video/mp4" && e.target.files[0].type != "video/mov"){
        alert(`${e.target.files[0].type}  not be allowed.`)
        e.target.value = null;
      }
    }
    const files = e.target.files;
    setSelectedFiles([...files]); // Use the spread operator to create a new array
    
  };

    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadVideo = (e) => {
        e.preventDefault();
        const formData = new FormData();
        // Append each selected file as 'video' to the formData
        selectedFiles.forEach((file) => {
            formData.append('video', file);
        });

        setIsLoading(true);

        axios({
            method: "post",
            url: `${API_URL.CREATE_VIDEO}upload-video/`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${localStorage.accessToken || ''}`,
            },
            onUploadProgress: (progressEvent) => {
                const total = progressEvent.total;
                const loaded = progressEvent.loaded;

                // Calculate and update progress
                const percentage = Math.round((loaded * 100) / total);
                setUploadProgress(percentage); // Update state
                console.log(`Upload Progress: ${percentage}%`);
            },
        })
        .then((res) => {
            if (res?.data?.uploaded_videos?.length > 0) {
                const newVideos = res.data.uploaded_videos.map((item) => ({
                    id: item.id || "",
                    video: item.video_url || "",  // Updated to match video URL field
                }));
                // Merge new videos with existing array or set new array
                setImgAry(imgArry && imgArry.length > 0 ? imgArry.concat(newVideos) : newVideos);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error("Error uploading video: ", error);
        });

        // Clear the form input fields and selected files
        setSelectedFiles([editId]);
};
const [removeCont, setRemoveCont] = useState('')
const removeImg = (id)=>{
 // alert(id)
  const newImgArry = imgArry.filter(item => item.id !== id)
  if(!removeCont){
    setRemoveCont('Deleted id -'+id)
  }else{
    setRemoveCont(removeCont + ', '+id)
  }
  
  setImgAry(newImgArry)
}
const [selectedDepartments, setSelectedDepartments] = useState([]);

  useEffect(() => {
    // Fetch tags
    // if(localStorage && localStorage.length > 0 && (localStorage.videoOpt || localStorage.editor)){
    //   setReadOn(false)
    // }
    if(localStorage && localStorage.length > 0 && localStorage.publishOpt && localStorage.publishOpt == 'publish'){
      setIsAccept(true)
    }
    
    getStringerData()
 
  }, [editId, msg,inputSearch]);
  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
  <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Edit Stn Video</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
            {!isload &&
              <div className="card card-outline card-primary">
                <div className="card-body">
                  <form>
                    <div className="form-row">
 
                      <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">Title: *</label>
                        <input
                          type="text"
                          name="title"
                          className="form-control"
                          id="inputHeading"
                          value={inputData.title}
                          onChange={handleChange}
                          readOnly ={readOn}
                        />
                      </div>
                    </div>

                    <div className="d-md-flex">
                        <div className="w-100">
                            <div className="form-group">
                                <label>Main Content *</label>
                                <div className='video-editor'>
                                  <ReactQuill className="form-control form-control-user editShortDesc" 
                                  theme="snow" 
                                  type="text"
                                  name="content" 
                                  value={conData} 
                                  onChange={setValue}
                                  readOnly ={readOn}
                                  />
                                </div>                                
                            </div>
                        </div>
                    </div>
                  <div className="form-row"> 
                      {/* <div className="form-group col-md-6">
                        <label htmlFor="inputLanguage">Language *</label>
                          <select
                            className="form-control"
                            name="language"
                            value={inputData.language}
                            onChange={handleChange}
                          >
                            <option value="">-- Select Language --</option>
                            <option value="en">English</option>
                            <option value="hi">Hindi</option>
                          </select>
                      </div>  */}
                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <label htmlFor="inputTLCategory">Stringers: *</label>
                          <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} aria-describedby="button-addon2" className="form-control border-0 bg-light" value={inputSearch}/>
                          <select className="form-control" name="stringers"  onChange={handleOnChange} value={inputData.stringers}>
                          {!inputSearch &&  strinData && strinData.length > 0 && <option value="">-- Select --</option>}
                          {inputSearch && (!strinData || strinData.length == 0) && <option value="">-- No data found --</option>}
                              {strinData && strinData.length > 0 && strinData.map((sData, s)=>{
                                  return(
                                      <>
                                      <option value={sData.id} key={s}>{sData.name}</option>
                                      </>
                                  )
                              })}
                            
                          </select>
                        </div>
										</div>
                    </div>
                    <div className='form-row'>
                      <div className="form-group col-md-6">
                          <label htmlFor="inputSlug">Created By *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="created_by"
                            value={inputData.created_by ? inputData.created_by : ''}
                            onChange={handleChange}
                            rows="6"
                            readOnly ={true}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputTLCategory">Event Type: *</label>
                          <select className="form-control" name="event_type" onChange={handleChange} value={inputData.event_type}>
                            <option value="">-- Select Event type --</option>
                            <option value="single_id">Single ID</option>
                            <option value="multi_id">Multi ID</option>
                            <option value="on_demand">On Demand</option>
                          </select>
                      </div>                 
                    </div>     
                                                                          
                <section className="content">
                    <div className="container-fluid">
                      <div className="col-lg-12">
                        <div className="card card-outline card-primary">
                          <div className="card-body">
                    {  !isload &&                  
                            <div className="col-md-12">																	
                              <div className="accordion" id="morePhoto">
                                <button className="addimg-btn btn btn-primary" type="button" onClick={()=>{
                                    setImgModal(!imgModal)
                                  }}>
                                + Add Videos
                                </button>

                              {isLoading     && 
                                                <div>
                                                <p>Uploading: {uploadProgress}%</p>
                                                <progress value={uploadProgress} max="100"></progress>
                                            </div>
                              }	
                                <div className="accordion" id="morePhoto">
                          {imgModal &&
                            <div>
                              <div className="card card-body">
                              <form encType="multipart/form-data" className="border p-2 mb-2 rounded">
                                    <div className="form-row">
                                    <div className="form-group col-md-2">
                                      <label htmlFor="files">Videos</label>
                                      <input
                                        type="file"
                                        onChange={handleFileChange}
                                        name="video"
                                        multiple
                                        required
                                        accept="video/mp4"
                                      />
                                    </div>
                                  </div>
                                  
                                  <div className="form-row">
                                    <button className="btn btn-success" onClick={uploadVideo}>
                                      Add
                                    </button>
                                    <button className="btn btn-secondary" type="button">
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>}  
                            </div>
                              </div>
                              
                              <ul className="pending-pic edit-event-pic edit-video">
                              {imgArry && imgArry.length > 0 && imgArry.map((imgg, im)=>{
                               let imggg =imgg.video ?  imgg.video:''
                                    return(
                                  <li key={im}>
                                    <video controls src={imggg}/>
                                    <div className="editpho-icon">
                                      <NavLink to={'#'} onClick={() => removeImg(imgg.id)}>
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                      </NavLink>
                                      {/* <NavLink to={'#'} onClick={()=>{
                                      }}>
                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                      </NavLink> */}
                                    </div>  
                                  </li>                            
                                    )
                                  })} 

                              </ul>
                            </div>
                            }
                            <div className="col-md-12">
                            {isload && 
                                <div className="load-img">
                                  Uploading...
                                  <div className="loader"></div>
                                </div>
                            }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                     
                        </div>
                      </div>        
                    </div>
                  </section>
                  <div className='form-row'>

                    <div className="form-group col-md-7" style={{marginLeft:'20px'}}>
                                              {/* <label htmlFor="inputHeading">Title: *</label> */}

                        <input
                                type="checkbox"
                                checked={markStatus}
                                onChange={handleChecked}
                                className="form-check-input"
                              />
                              <label className="inputHeading" htmlFor="gridCheck">
                              Mark Important: *{markStatus}
                              </label>
                      </div>  
{/* { localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='All content' &&
                    <div className="form-group col-md-6">
                      <label htmlFor="inputCategory">Assign to *</label>
                        <Multiselect
                          options={departmentsData}
                          displayValue="name"
                          selectedValues={departData}
                          onSelect={(selectedList) => setdepartData(selectedList)}
                          onRemove={(selectedList) => setdepartData(selectedList)}
                        />
                    </div>} */}



                    <div className="form-group col-md-12">
                      <label htmlFor="inputCategory">Assign to *</label>
                      <div className="checkboxes__row" >
                        {departmentsData.map((dep) => (
                          <div className="checkboxes__item" key={dep.id}>
                            <label className="checkbox style-h">
                              <input
                                type="checkbox"
                                value={dep.id}
                                name={dep.slug}
                                checked={selectedDepartments.includes(dep.id)}
                                onChange={() => handleCheckboxChange(dep.id)}
                              />
                              <div className="checkbox__checkmark"></div>
                              <div className="checkbox__body">{dep.name}</div>
                            </label>
                          </div>
                        ))}
                       </div>
                    </div>
                  </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputSlug">Remarks *</label>
                        <input
                          type="text"
                          className="form-control"
                          name="remarks"
                          value={inputData.remarks}
                          // readOnly={true}
                          onChange={handleChange}
                          
                        />
                      </div>
                                          
                    </div>
                {localStorage && localStorage.langg && localStorage.langg == 'All content' &&  
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputSlug">Slug</label>
                        <input
                          type="text"
                          className="form-control"
                          name="slug"
                          value={inputData.slug}
                          // readOnly={true}
                          onChange={handleChange}
                          
                        />
                      </div>
                    </div>
                  }
                    <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                    {/* {  localStorage && localStorage.length > 0 && localStorage.videoOpt &&      */}
                  { localStorage.langg != 'SM' && <button className="btn btn-primary" type="button" onClick={() => handleSubmit('pending')}>Save</button>}
                    {  localStorage && localStorage.length > 0 && localStorage.langg &&  localStorage.langg !='All content' && localStorage.langg != 'SM' &&  
                         <button className="btn btn-success" type="button" onClick={() => handleSubmit('submitted')}>Submit</button>
                    }
                    {/* }                   */}
                    {  localStorage && localStorage.length > 0 && isAccept && localStorage.langg != 'SM' &&

                      <button className="btn btn-success" type="button" onClick={() => emailAfter('approved')}>Approve</button>
                    }
                    {  localStorage && localStorage.length > 0 && isAccept && localStorage.langg != 'SM' &&
                      
                      <button className="btn btn-warning" type="button" onClick={() => handleSubmit('hold')}>Hold</button>

                    }                    
                    {  localStorage && localStorage.length > 0 && isAccept &&     localStorage.langg != 'SM' &&
                      <button className="btn btn-danger" type="button" onClick={() => emailAfter(2)}>Reject</button>
                    } 
                   {  localStorage && localStorage.length > 0 && localStorage.langg &&  localStorage.langg == 'SM' &&     localStorage.langg != inputData.SM && 
                      <button className="btn btn-success" type="button" onClick={() => emailAfter(2)}>Accept</button>
                    } 
                      </div>   
                  </form>
                </div>
              </div>
              }
              {isload &&
                <div className="col-md-12">
                    <div className="load-img">
                      Loading...
                      <div className="loader"></div>
                    </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default EditStnVideo;
