import React,{useState, useEffect} from 'react';
import {NavLink, useHistory} from 'react-router-dom'
import axios from 'axios';
import API_URL from './ApiData'
import { useLocation, Redirect } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

const Header =()=>{
    const [loginStatus, setLoginStatus] = useState(false);
    const locale = 'en';
    const [justShow, setJustShow] = useState(false)
    const [updatePass, setUpdatePass] = useState(false)
    const [NewsShow, setNewsShow] = useState(false)
    const [VideoStrShow, setVideoShow] = useState(false)
    const [PhotoStnShow, setPhotoStnShow] = useState(false)
    const handleJustClose = () => setJustShow(false)
    const handleNewsClose = () => setNewsShow(false)

    const [isLoading, setIsLoading] = useState(false);

    const [msg, setMsg] = useState('');
    const [currentPath, setCurrntPage] = useState('');
    const [userNamee, setLoginUsername] = useState('');
    const history = useHistory();
    const [csss, setcsss] = useState('modal fade')
    const [chPass, setchPass] = useState('modal fade')
    const [search, setSearch] = useState();

    const getCurrentTimeInIST = () => {
      const now = new Date();
      
      // Calculate IST by adding 5 hours and 30 minutes (in milliseconds)
      const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
  
      // Format the date to 'YYYY-MM-DD HH:mm:ss'
      const formattedDate = istTime.toISOString().replace('T', ' ').substring(0, 19);
      
      return `${formattedDate} +05:30`; // Append the IST offset
  };
  
  // Usage
  //console.log(getCurrentTimeInIST());
  
    const getCurrentDateTime = () => {
      const now = new Date();

      // Format date part as 'YYYY-MM-DDTHH:mm:ss'
      const isoString = now.toISOString().split('.')[0];

      // Get the timezone offset in hours and minutes
      const timezoneOffset = -now.getTimezoneOffset();
      const offsetHours = Math.floor(Math.abs(timezoneOffset) / 60)
        .toString()
        .padStart(2, '0');
      const offsetMinutes = (Math.abs(timezoneOffset) % 60)
        .toString()
        .padStart(2, '0');
      const offsetSign = timezoneOffset >= 0 ? '+' : '-';

      // Construct the final string with timezone offset
      const formattedDate = `${isoString}.${now
        .getMilliseconds()
        .toString()
        .padEnd(6, '0')}${offsetSign}${offsetHours}:${offsetMinutes}`;

      return formattedDate;
    };
    const [createData, setCreateData] = useState({
      title:'',
      summary :'',
      assign_me : true
    })
    const [changePass, setChangePass] = useState({
      current_password:'',
      new_password :'',
      confirm_password : ''
    })
    const [inpustJust, setInputJust] = useState({
      content:'',
      filename:'',
      created_at:getCurrentTimeInIST(),
      updated_at:getCurrentTimeInIST()
    })
    const handleOnChange = event => {
      const { name, value } = event.target;
      setCreateData({ ...createData, [name]: value });
    }
    const [newsData, setNewsData] = useState({
      title:'',
      description:'',
      created_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : ''
    })   
    const [videoStnData, setVideoStnData] = useState({
      title:'',
      description:'',
      created_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : ''
    }) 
    const [photoStnData, setPhotoStnData] = useState({
      title:'',
      caption:'',
      created_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : ''
    }) 
    const handleOnJustChange = event=>{
      const {name, value} =event.target;
      setInputJust({ ...inpustJust, [name]: value });

    }
    const handleOnNewsChange = event=>{
      const {name, value} =event.target;
      setNewsData({ ...newsData, [name]: value });
    }
    const handleOnVideoStnChange = event=>{
      const {name, value} =event.target;
      setVideoStnData({ ...videoStnData, [name]: value });
    }
    const handleOnPassChange = event=>{
      const {name, value} =event.target;
      setChangePass({ ...changePass, [name]: value });
    }
    const handleOnPhotoStnChange = event=>{
      const {name, value} =event.target;
      setPhotoStnData({ ...photoStnData, [name]: value });
    }
      const location = useLocation();
      const { from } = location.state || { from: { pathname: '/login/' } };
      const mailSendData = (data)=>{
       
         var currentDate = formatDateTime(); 

         var formMailData = new FormData()

         var tempTitle = data.title.replace("\r\n", " ");
         
         formMailData.append('title',"New Story: "+ tempTitle)
         formMailData.append('description', "Created_at : "+currentDate+"\r\n Created_By : "+ localStorage.userName+"\r\n \r\n"+"Summary : "+data.summary)
         //formMailData.append('created_at', data.created_at)
     
         const mailApi = `https://cms.iansnews.in/api/email-before-edit/`; 
         axios({
           method: "post",
           url: mailApi,
           data: formMailData,
           headers: { 
             "Content-Type": "multipart/form-data",
           Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
         },
         }).then((res) => {
           if(res.data && res.data.message && res.data.message == "Email sent successfully"){
            setMsg('')
            setCreateData({
              assign_me : true,
              title : '',
              summary : ''
            })
            setcsss('modal fade');
            setTimeout(
              window.location.href = "/", 10000);
           }
           

         })
    
    } 
    const formatDateTime = () => {
      const dateString = new Date();
      const date = new Date(dateString);
    
      // Extracting parts of the date
      const day = String(date.getDate()).padStart(2, '0'); // Get day and pad it to 2 digits
      const month = date.toLocaleString('default', { month: 'short' }); // Get month as short name
      const year = date.getFullYear(); // Get full year
    
      // Formatting the time
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      const timeString = new Intl.DateTimeFormat('en-US', options).format(date);
    
      return `${day} ${month} ${year}, ${timeString}`;
    };
      const CreateStory = ()=>{
        var formData = new FormData();
        if(!createData.title){
          setMsg('Title can not be blank.')
        }else if(!createData.summary){
          setMsg('Summary can not be blank.')
        }else{
          formData.append('assign_me', createData.assign_me);
          formData.append('title', createData.title);
          formData.append('summary', createData.summary);
         // formData.append('created_at',getCurrentTimeInIST());
          const getStateApi =API_URL.Create_Story_URL; 
          axios({
            method: "post",
            url: getStateApi,
            data: formData,
            headers: { 
              "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
          },
            
          }).then((res) => {

            setTimeout(
              createLoad(), 50000);
          });
        }
        }
        const CreateNewsIn = () => {
          setIsLoading(true)
          var formNewsData = new FormData();
          
          if (!newsData.title) {
            setMsg('Title cannot be blank.');
            setIsLoading(false)
          } else if (!newsData.description) {
            setMsg('Description cannot be blank.');
            setIsLoading(false)
          } else {
            formNewsData.append('title', newsData.title);
            formNewsData.append('description', newsData.description);
            formNewsData.append('created_by', newsData.created_by);
            formNewsData.append('status', 'pending');
            formNewsData.append('created_at',getCurrentTimeInIST());
            
            const getStateApi = API_URL.CREATE_NEWS;
            
            axios({
              method: "post",
              url: getStateApi,
              data: formNewsData,
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
              },
            })
            .then((res) => {
              if(res && res.data && res.data.id){
                emailAfter(newsData)
                setIsLoading(false)
                setcsss('modal fade')
                setNewsShow(false)
                setTimeout(
                  // history.push('/preview-news/'+res.data.id), 50000);
                  // history.push('/edit-stn-news/'+res.data.id)
                  window.location.href = '/edit-stn-news/' + res.data.id // Redirect with full reload

                  , 50000);

              }
              console.log("Success:", res.data);
              // Handle success
            })
            .catch((err) => {
              console.error("Error:", err.response ? err.response.data : err.message);
              setMsg(err.response ? err.response.data : "Error occurred while sending data");
              setIsLoading(false)
            });
          }
        }

        const CreateVideoStnIn = () => {
          setIsLoading(true)
          var formVideoData = new FormData();
          
          if (!videoStnData.title) {
            setMsg('Title cannot be blank.')
            setIsLoading(false)
          } else if (!videoStnData.description) {
            setMsg('Description cannot be blank.')
            setIsLoading(false)
          } else {
            formVideoData.append('title', videoStnData.title);
            formVideoData.append('description', videoStnData.description);
            formVideoData.append('created_by', newsData.created_by);
            formVideoData.append('created_at',getCurrentTimeInIST());
            
            const getStateApi = API_URL.CREATE_VIDEO;
            
            axios({
              method: "post",
              url: getStateApi,
              data: formVideoData,
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
              },
            })
            .then((res) => {
              console.log("Success:", res.data)
              if(res && res.data && res.data.id){
                emailAfter(videoStnData)
                setIsLoading(false)
                setcsss('modal fade')
                setNewsShow(false)
                setTimeout(
                  // history.push('/edit-stn-video/'+res.data.id)
                  window.location.href = '/edit-stn-video/' + res.data.id // Redirect with full reload

                  , 50000);
               
              }    
                        // Handle success
            })
            .catch((err) => {
              console.error("Error:", err.response ? err.response.data : err.message);
              setMsg(err.response ? err.response.data : "Error occurred while sending data");
              setIsLoading(false)
            });
          }
        }
        const passwordChange = () => {
          //setIsLoading(true)
          var formpassData = new FormData();
          
          if (!changePass.current_password) {
            setMsg('Current password cannot be blank.')
            setIsLoading(false)

          } else if (!changePass.new_password) {
            setMsg('New password cannot be blank.')
            setIsLoading(false)
          } else if (!changePass.confirm_password) {
            setMsg('Confirm password cannot be blank.')
            setIsLoading(false)            
          } else if (changePass.new_password != changePass.confirm_password) {
            setMsg('Recheck password(Not same).')
            setIsLoading(false)                        
          } else {
            formpassData.append('old_password', changePass.current_password);
            formpassData.append('new_password', changePass.confirm_password);
            
            const getStateApi = API_URL.CHANGE_PASS;
            
            axios({
              method: "post",
              url: getStateApi,
              data: formpassData,
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
              },
            })
            .then((res) => {
              console.log("Success:", res.data);
             // if(res && res.data && res.data.id){
               // emailAfter(photoStnData)
                //setIsLoading(false)
                setChangePass('modal fade')
               // setNewsShow(false)
               localStorage.clear()
               
                setTimeout(
                  // history.push('/edit-stn-photos/'+res.data.id)
                  window.location.href = '/login' // Redirect with full reload

                  , 50000);
               
              //}   
              // Handle success  
            })
            .catch((err) => {
              console.error("Error:", err.response ? err.response.data : err.message);
              setMsg(err.response && err.response.data && err.response.data.detail ? err.response.data.detail : "Error occurred while sending data");
              setIsLoading(false)
            });
          }
        }
        const CreatePhotoStnIn = () => {
          setIsLoading(true)
          var formPhotoData = new FormData();
          
          if (!photoStnData.title) {
            setMsg('Title cannot be blank.')
            setIsLoading(false)
          } else if (!photoStnData.description) {
            setMsg('Caption cannot be blank.')
            setIsLoading(false)
          } else {
            formPhotoData.append('title', photoStnData.title);
            formPhotoData.append('caption', photoStnData.description);
            formPhotoData.append('created_by', photoStnData.created_by);
            formPhotoData.append('created_at',getCurrentTimeInIST());
            
            const getStateApi = API_URL.CREATE_PHOTO;
            
            axios({
              method: "post",
              url: getStateApi,
              data: formPhotoData,
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
              },
            })
            .then((res) => {
              console.log("Success:", res.data);
              if(res && res.data && res.data.id){
                emailAfter(photoStnData)
                setIsLoading(false)
                setcsss('modal fade')
                setNewsShow(false)
               
                setTimeout(
                  // history.push('/edit-stn-photos/'+res.data.id)
                  window.location.href = '/edit-stn-photos/' + res.data.id // Redirect with full reload

                  , 50000);
               
              }   
              // Handle success
            })
            .catch((err) => {
              console.error("Error:", err.response ? err.response.data : err.message);
              setMsg(err.response ? err.response.data : "Error occurred while sending data");
              setIsLoading(false)
            });
          }
        }
        const emailAfter =(gettt)=>{
        let tempDesc ="";
        var currenteDate = formatDateTime(); 

        tempDesc = "Created_at : "+currenteDate+"\r\n Created_By : "+ localStorage.userName+"\r\n \r\n"+"Description : "+ gettt.description;

          let eData =
            {
              "title": gettt.title,
              "description": tempDesc,
              "Stringer_name":localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : ""
            }
          
            axios({
              method: "post",
              url: `https://cms.iansnews.in/api/email-before-edit/`,
              data: eData,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
              },
            }).then((response) => {
              if (response.status === 200) {
                console.log('Success:', response.data);
               // setIsload(false)
                // if(type && type !=4){
                //   history.push('/all-photos-stn'); // Redirect to the home page on success
        
                // }
               // handleSubmit(ty)
              }
            })
          }
        const getCurrentDate = () => {
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const day = String(today.getDate()).padStart(2, '0');
        
          return `${year}-${month}-${day}`;
        };
        const CreateJustIn = ()=>{
          var formData = new FormData();
          if(!inpustJust.content){
            setMsg('Content can not be blank.')
          }else if(!inpustJust.filename){
            setMsg('File Name can not be blank.')
          }else{
            formData.append('filename', inpustJust.filename+'.mp4');
            formData.append('content', inpustJust.content);
            formData.append('slug',inpustJust.filename+'-'+getCurrentDate())
            formData.append('created_at', inpustJust.created_at);
            formData.append('updated_at', inpustJust.updated_at);

            const getJustAddApi =API_URL.ADD_JUST_IN; 
            axios({
              method: "post",
              url: getJustAddApi,
              data: formData,
              headers: { 
                "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
            },
              
            }).then((res) => {
              setInputJust({
                content:'',
                filename:'',
                created_at:getCurrentTimeInIST(),
                updated_at:getCurrentTimeInIST()
              })
              setcsss('modal fade')
              setJustShow(false)
              // setTimeout(
              //   createLoad(), 50000);
            });
          }
          }        
      const createLoad = ()=>{
        // mailSendData(createData, '','')
       mailSendData(createData)
      }
      const openMadal = ()=>{
        setMsg('')
        setcsss('modal fade show');
      }
      const showJustModal = () =>{
        setMsg('')
        setcsss('modal fade show');
        setJustShow(true)
      }
      const showNewsModal = () =>{
        setMsg('')
        setcsss('modal fade show');
        setNewsShow(true)
      }
      const showVidModal = () =>{
        setMsg('')
        setcsss('modal fade show');
        setVideoShow(true)
      }
      const showPhotoStnModal = () =>{
        setMsg('')
        setcsss('modal fade show');
        setPhotoStnShow(true)
      }
      const changePassOpen =()=>{
       // alert();
       setchPass('modal fade show');
        setUpdatePass(true)
      }
      useEffect(() => {
        if(!localStorage || !localStorage.accessToken){
            setLoginStatus(true);
            history.push('/login/');
        }
        
        if(location && location.pathname){
          let setCurPath = location.pathname.split("/");
          if(setCurPath && setCurPath.length > 1){
            setCurrntPage(setCurPath[1] ? setCurPath[1] : 'home');
          }
        }
        if(localStorage && localStorage.accessToken){
          setLoginUsername(localStorage.userName);
          }
      }, [localStorage]);
    return (
        <>
                  
          <nav className="main-header navbar navbar-expand navbar-white navbar-light text-sm">
            <ul className="navbar-nav user-n">
              <li className="nav-user nav-item d-none d-sm-inline-block">
                <i className="fas fa-user"></i> Welcome -- {userNamee} 
              </li>
            </ul>

            {/* <div className="bracking-main">
              <div className="container">
                <div className="bracking-new-mob">
                  <div className="braking-inner">
                  <div className="bracking-left">
                    <h2>Breaking</h2>
                  </div>
                  <div className="brack-text-rigt">
                    <a title="#" href="#">Indian markets to stabilise towards Q4 2025 end, FPI flows to turn positive: Report</a>
                  </div>
                  </div>
                </div>
              </div>
            </div> */}

            <ul className="navbar-nav ml-auto">
              { localStorage && localStorage.langg &&                                              
              <li className="nav-logout nav-item" onClick={changePassOpen}>
                <a href="javascript:void(0)"> <i className="fa-solid fa-key"></i>Change Password</a>
              </li>}      
              <li className="nav-logout nav-item">
                <NavLink className="nav-link"  to="/logout" role="button" >
                <i className="fas fa-sign-out-alt"></i> Logout
                </NavLink>
              </li>
              <li className="nav-item pmenu">
              <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
              </li>
            </ul>
          </nav>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="#" className="brand-link text-sm">
              <span className="brand-text font-weight-light">IANS- ADMIN </span>
            </a>
            <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-transition os-host-scrollbar-horizontal-hidden">
              <div className="os-padding">
                <div className="os-viewport os-viewport-native-scrollbars-invisible">
                  <div className="os-content">
                    <nav className="mt-2">
                      <ul className="nav nav-pills nav-sidebar flex-column nav-flat" >
                      { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath == 'home' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/"> <img className="nav-i-icon" src="/assets/icons/stn-news.png"/> <p> All Stories</p></a>
                        </li>
                      }
                        {/* all string hindi and english news start */}

                      { 
                        localStorage && localStorage.langg &&  localStorage.langg=='hindi' &&                                                 
                        <li className={currentPath =='all-hindi-news' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-hindi-news"> <img className="nav-i-icon" src="/assets/icons/reporter-news.png"/> <p>Reporter News</p></a>
                        </li>
                        }                   
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='ce' &&                                                 
                        <li className={currentPath =='all-english-news' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-english-news"> <img className="nav-i-icon" src="/assets/icons/reporter-news.png"/> <p>Reporter News</p></a>
                        </li>
                        } 

                        {/* all string hindi and english news end */}
                       { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='mystories' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/mystories" > <img className="nav-i-icon" src="/assets/icons/my-stories.png"/> <p> My Stories</p></a>
                        </li>
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='flagged' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/flagged"> <img className="nav-i-icon" src="/assets/icons/flag.png"/> <p> Flagged Stories</p></a>
                        </li>
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='pending' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/pending"> <img className="nav-i-icon" src="/assets/icons/clock.png"/> <p> Pending for Review</p></a>
                        </li>
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&

                        <li className={currentPath =='published' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/published"> <img className="nav-i-icon" src="/assets/icons/published.png"/> <p> Published</p></a>
                        </li>
                      } { localStorage && localStorage.langg && localStorage.langg=='hindi' &&

                       <li className={currentPath =='published-english' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/published-english"> <img className="nav-i-icon" src="/assets/icons/published-eng.png"/> <p> Published (ENG)</p></a>
                        </li> 
                      } { localStorage && localStorage.langg && (localStorage.langg=='hindi' || localStorage.langg=='ce')  &&
                                           
                        <li className={'nav-link nav-news'} onClick={()=>{
                          openMadal()
                        }}>
                          <a href="#" > <img className="nav-i-icon" src="/assets/icons/plus.png"/> <p> Add News</p></a>
                        </li>
                      }
                        { localStorage && localStorage.langg && localStorage.langg=='mt' && 
                        <li className={currentPath =='all-pictures' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-pictures"> <i className="nav-icon fas fa-images"></i> <p> All Pictures</p></a>
                        </li> 
                        }
                      {/* photo stn access start */}
                       { 
                        localStorage && localStorage.langg &&  localStorage.langg=='All content' &&                                                  
                        <li className={currentPath =='all-photos-stn' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-photos-stn" title="All Photos Stn"> <img className="nav-i-icon" src="/assets/icons/stn-photos.png"/> <p>Photos</p></a>
                        </li>
                      }  
                                            {/* photo stn access end */}

                        { localStorage && localStorage.langg && localStorage.langg=='mt'     &&     
                        <li className={currentPath =='pending-pictures' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/pending-pictures"> <i className="nav-icon fas fa-clock"></i> <p> Pending Pictures</p></a>
                        </li> 
                         }
                         { localStorage && localStorage.langg && localStorage.langg=='mt'     && 
                        <li className={currentPath =='completed-pictures' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/completed-pictures"> <i className="nav-icon fas fa-image"></i> <p> Completed Pictures</p></a>
                        </li> 
                         }
                         { localStorage && localStorage.langg && localStorage.langg=='mt'     &&                                                 
                        <li className={currentPath =='add-new' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/add-new"> <i className="nav-icon fas fa-plus"></i> <p>Add New Event</p></a>
                        </li>}
                        { localStorage && localStorage.langg &&  (localStorage.langg=='Web Team' || localStorage.uploader || localStorage.webTeam) &&                                                 
                        <li className={currentPath =='add-video' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/add-video"> <img className="nav-i-icon" src="/assets/icons/video-plus.png"/> <p>Add New Video</p></a>
                        </li>}                         
                        { localStorage && localStorage.langg &&  (localStorage.langg=='Web Team' || localStorage.editor || localStorage.uploader || localStorage.webTeam) &&                                                 
                        <li className={currentPath =='all-videos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-videos"> <img className="nav-i-icon" src="/assets/icons/playlist.png"/> <p>All Videos</p></a>
                        </li>}                         
                        { 
                        localStorage && localStorage.langg &&  localStorage.uploader &&                                                  
                        <li className={currentPath =='just-in' ? 'nav-link nav-news active' : 'nav-link nav-news'} onClick={()=>{
                          showJustModal()
                        }}>
                          <a href="#"> <img className="nav-i-icon" src="/assets/icons/just-in.png"/> <p>Add Just-In</p></a>
                        </li>
                        }
                        { localStorage && localStorage.langg && localStorage.uploader    &&                                                 
                        <li className={currentPath =='just-in-list' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/just-in-list"> <img className="nav-i-icon" src="/assets/icons/p-just-in.png"/> <p>All Just-In</p></a>
                        </li>
                        } 


 
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='All content' &&                                                 
                        <li className={currentPath =='all-video-stn' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-video-stn" title="All Stn Videos"> <img className="nav-i-icon" src="/assets/icons/stn-all-video.png"/> <p>Videos</p></a>
                        </li>
                        }
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='stn_video' &&                                                 
                        <li className={currentPath =='add-new' ? 'nav-link nav-news active' : 'nav-link nav-news'} onClick={()=>{
                          showVidModal()
                        }}>
                          <a href="#"> <img className="nav-i-icon" src="/assets/icons/video-plus.png"/> <p>Add Stn Video</p></a>
                        </li>
                        }   
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='All content' &&                                                 
                        <li className={currentPath =='all-news' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-news" title="All STN News"> <img className="nav-i-icon" src="/assets/icons/stn-news.png"/> <p>News</p></a>
                        </li>
                        } 
                               
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='stn_news' &&                                                   
                        <li className={currentPath =='add-new' ? 'nav-link nav-news active' : 'nav-link nav-news'} onClick={()=>{
                          showNewsModal()
                        }}>
                          <a href="#"> <img className="nav-i-icon" src="/assets/icons/add-stn-news.png"/> <p>Add News</p></a>
                        </li>
                        }  
                       {/* { 
                        localStorage && localStorage.langg && localStorage.langg=='stn_photo' &&                                                  
                        <li className={currentPath =='all-photos-stn' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/all-photos-stn"> <img className="nav-i-icon" src="/assets/icons/stn-photos.png"/> <p>All Photos Stn</p></a>
                        </li>}    */}
                       { 
                        localStorage && localStorage.langg &&  localStorage.langg=='stn_photo' &&                                                  
                        <li className={currentPath =='add-new' ? 'nav-link nav-news active' : 'nav-link nav-news'} onClick={()=>{
                          showPhotoStnModal()
                        }}>
                          <a href="#"> <img className="nav-i-icon" src="/assets/icons/stn-add-photos.png"/> <p>Add Photos</p></a>
                        </li>
                        }  
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='stn_news' &&                                                 
                        <li className={currentPath =='my-news' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/my-news"> <img className="nav-i-icon" src="/assets/icons/stn-user.png"/> <p>My Basket</p></a>
                        </li>} 
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='stn_video' &&                                                 
                        <li className={currentPath =='my-videos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/my-videos"> <img className="nav-i-icon" src="/assets/icons/stn-user.png"/> <p>My Basket</p></a>
                        </li>
                        } 
                        { 
                        localStorage && localStorage.langg && localStorage.langg=='SM'  &&                                                 
                        <li className={currentPath =='socialmedia' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/socialmedia"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>}     
                        { 
                        localStorage && localStorage.langg && localStorage.langg=='SM EDIT'  &&                                                 
                        <li className={currentPath =='socialmediaeditor' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/socialmediaeditor"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>}                         
                        { 
                        localStorage && localStorage.langg && (localStorage.langg=='MM' || localStorage.langg=='Web Team' || localStorage.langg=='Uploader' || localStorage.langg=='Video Editor' || localStorage.webTeam) &&                                                 
                        <li className={currentPath =='multimedia-videos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/multimedia-videos"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>}     
                        { 
                        localStorage && localStorage.langg && localStorage.langg=='BUS' &&                                                 
                        <li className={currentPath =='business-videos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/business-videos"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>} 
                        { 
                        localStorage && localStorage.langg && localStorage.langg=='ENT' &&                                                 
                        <li className={currentPath =='entertainment-videos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/entertainment-videos"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>
                        }   
                        { 
                        localStorage && localStorage.langg && (localStorage.langg=='ENG' || localStorage.langg=='ce')  &&                                                 
                        <li className={currentPath =='englishvideos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/englishvideos"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>}      
                        { 
                        localStorage && localStorage.langg && (localStorage.langg=='HIN' || localStorage.langg=='hindi') &&                                                 
                        <li className={currentPath =='hindivideos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/hindivideos"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>}     
                        { 
                        localStorage && localStorage.langg && localStorage.langg=='ST' &&                                                 
                        <li className={currentPath =='science-techvideos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/science-techvideos"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>} 
                        { 
                        localStorage && localStorage.langg && localStorage.langg=='SPT' &&                                                 
                        <li className={currentPath =='sportsvideos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/sportsvideos"> <img className="nav-i-icon" src="/assets/icons/stingers-video.png"/> <p>Input Videos</p></a>
                        </li>
                        }                                              
                        { 
                        localStorage && localStorage.langg &&  localStorage.langg=='stn_photo' &&                                                 
                        <li className={currentPath =='my-photos' ? 'nav-link nav-news active' : 'nav-link nav-news'}>
                          <a href="/my-photos"> <img className="nav-i-icon" src="/assets/icons/stn-user.png"/> <p>My Basket</p></a>
                        </li>}                      
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </aside> 
          {!NewsShow && !VideoStrShow && !PhotoStnShow && !updatePass && 
          <div className={csss} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:csss == 'modal fade'? 'none' : 'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add News</h5>
                  <button type="button" className="close" onClick={()=>{
                  setcsss('modal fade')
                }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <p style={{textAlign:'center',fontSize:'18px',color:'red', fontWeight:'500'}}>{msg}</p>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input type="text" className="form-control" placeholder="Enter your title" onChange={handleOnChange} value={createData.title} name="title"/>
                        <div  className="form-text text-danger error" style={{display:'none'}}  >Enter Your Title</div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="shortDesc">Summary</label>
                        <textarea className="form-control form-control-user" placeholder="Summary" onChange={handleOnChange} value={createData.summary} rows="12" name="summary" required=""></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={()=>{
                    CreateStory()
                  }}>Save</button>
                  <button type="button" className="btn btn-secondary" onClick={()=>{
                    setcsss('modal fade')
                  }}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
          }
{  NewsShow  &&  
         <div className={csss} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:csss == 'modal fade'? 'none' : 'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add News</h5>
                  <button type="button" className="close" onClick={()=>{
                  setcsss('modal fade')
                }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                <p style={{textAlign:'center',fontSize:'18px',color:'red', fontWeight:'500'}}>{msg}</p>
                {!isLoading  &&   
                  <div className="row">
                    <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="title">Title*</label>
                        <input type="text" className="form-control" placeholder="Title" onChange={handleOnNewsChange} name="title" value={newsData.title}/>
                        <div className="form-text text-danger error" style={{display:'none'}}>Title</div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="shortDesc">Description*</label>
                        <textarea className="form-control form-control-user" placeholder="Description" onChange={handleOnNewsChange} rows="12" name="description">{newsData.description}</textarea>
                      </div>
                    </div>
                  </div>
                }
                {isLoading && 
                    <div className="card-body">
                        <div className="col-md-12">
                          <div className="load-img">
                              Uploading...
                              <div className="loader"></div>
                          </div>  
                        </div>  
                    </div>  
                }
                </div>
              { !isLoading &&
               <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={()=>{
                    CreateNewsIn()
                  }}>Save</button>
                  <button type="button" className="btn btn-secondary" onClick={()=>{
                    setcsss('modal fade')
                  }}>Cancel</button>
                </div>
                }
              </div>
            </div>
          </div>}
          {  VideoStrShow  &&  
         <div className={csss} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:csss == 'modal fade'? 'none' : 'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add Str Video</h5>
                  <button type="button" className="close" onClick={()=>{
                  setcsss('modal fade')
                }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                <p style={{textAlign:'center',fontSize:'18px',color:'red', fontWeight:'500'}}>{msg}</p>
                {!isLoading &&
                  <div className="row">
                    <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="title">Title*</label>
                        <input type="text" className="form-control" placeholder="Title" onChange={handleOnVideoStnChange} name="title" value={videoStnData.title}/>
                        <div className="form-text text-danger error" style={{display:'none'}}>Title</div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="shortDesc">Description*</label>
                        <textarea className="form-control form-control-user" placeholder="Description" onChange={handleOnVideoStnChange} rows="12" name="description">{videoStnData.description}</textarea>
                      </div>
                    </div>
                  </div>
                }
                {isLoading && 
                    <div className="card-body">
                        <div className="col-md-12">
                          <div className="load-img">
                              Uploading...
                              <div className="loader"></div>
                          </div>  
                        </div>  
                    </div>  
                }
                </div>
                {!isLoading &&
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                      CreateVideoStnIn()
                    }}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={()=>{
                      setcsss('modal fade')
                    }}>Cancel</button>
                  </div>
                }
              </div>
            </div>
          </div>}

          {updatePass && 
         <div className={chPass} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:chPass == 'modal fade'? 'none' : 'block'}}>
         <div className="modal-dialog" role="document">
           <div className="modal-content">
             <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">Change Password</h5>
               <button type="button" className="close" onClick={()=>{
               setchPass('modal fade')
             }}>
                 <span aria-hidden="true">&times;</span>
               </button>
             </div>
             <div className="modal-body">
             <p style={{textAlign:'center',fontSize:'18px',color:'red', fontWeight:'500'}}>{msg}</p>
             {!isLoading &&
               <div className="row">
                 <div className="col-md-12">
                 <div className="form-group">
                     <label htmlFor="title">Current Password*</label>
                     <input type="password" className="form-control" placeholder="Current Password" onChange={handleOnPassChange} name="current_password" value={changePass.current_password}/>
                     <div className="form-text text-danger error" style={{display:'none'}}>Current Password</div>
                   </div>
                   <div className="form-group">
                     <label htmlFor="title">New Password*</label>
                     <input type="password" className="form-control" placeholder="New Password" onChange={handleOnPassChange} name="new_password" value={changePass.new_password}/>
                     <div className="form-text text-danger error" style={{display:'none'}}>New Password</div>
                   </div>
                   <div className="form-group">
                     <label htmlFor="title">Confirm Password*</label>
                     <input type="password" className="form-control" placeholder="Confirm Password" onChange={handleOnPassChange} name="confirm_password" value={changePass.confirm_password}/>
                     <div className="form-text text-danger error" style={{display:'none'}}>Confirm Password</div>
                   </div>                      
                 </div>
               </div>
             }
             {isLoading && 
                 <div className="card-body">
                     <div className="col-md-12">
                       <div className="load-img">
                           Uploading...
                           <div className="loader"></div>
                       </div>  
                     </div>  
                 </div>  
             }
             </div>
             {!isLoading &&
               <div className="modal-footer">
                 <button type="button" className="btn btn-primary" onClick={()=>{
                   passwordChange()
                 }}>Update Password</button>
                 <button type="button" className="btn btn-secondary" onClick={()=>{
                   setchPass('modal fade')
                 }}>Cancel</button>
               </div>
             }
           </div>
         </div>
         </div>   
          }     
          {   justShow &&  
         <div className={csss} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:csss == 'modal fade'? 'none' : 'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add Just In</h5>
                  <button type="button" className="close" onClick={()=>{
                  setcsss('modal fade')
                }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                <p style={{textAlign:'center',fontSize:'18px',color:'red', fontWeight:'500'}}>{msg}</p>
                {!isLoading && 
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="shortDesc">Summary*</label>
                        <textarea className="form-control form-control-user" placeholder="Summary" onChange={handleOnJustChange} rows="12" name="content">{inpustJust.content}</textarea>
                      </div>
                      <div className="form-group">
                        <label htmlFor="title">File Name*</label>
                        <input type="text" className="form-control" placeholder="Enter your file name" onChange={handleOnJustChange} name="filename" value={inpustJust.filename}/>
                        <div className="form-text text-danger error" style={{display:'none'}}>File Name</div>
                      </div>
                    </div>
                  </div>
                  }
                  {isLoading && 
                    <div className="card-body">
                        <div className="col-md-12">
                          <div className="load-img">
                              Uploading...
                              <div className="loader"></div>
                          </div>  
                        </div>  
                    </div>  
                  }
                </div>
                {!isLoading && 
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={()=>{
                      CreateJustIn()
                    }}>Save</button>
                    <button type="button" className="btn btn-secondary" onClick={()=>{
                      setcsss('modal fade')
                    }}>Cancel</button>
                  </div>
                }
              </div>
            </div>
          </div>}

          {  PhotoStnShow  &&  
         <div className={csss} id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:csss == 'modal fade'? 'none' : 'block'}}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Add Str Photo</h5>
                  <button type="button" className="close" onClick={()=>{
                  setcsss('modal fade')
                }}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                <p style={{textAlign:'center',fontSize:'18px',color:'red', fontWeight:'500'}}>{msg}</p>
                {!isLoading &&
                  <div className="row">
                    <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="title">Title*</label>
                        <input type="text" className="form-control" placeholder="Title" onChange={handleOnPhotoStnChange} name="title" value={photoStnData.title}/>
                        <div className="form-text text-danger error" style={{display:'none'}}>Title</div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="shortDesc">Description*</label>
                        <textarea className="form-control form-control-user" placeholder="Description" onChange={handleOnPhotoStnChange} rows="12" name="description">{photoStnData.description}</textarea>
                      </div>
                    </div>
                  </div>
                }
                {isLoading && 
                  <div className="card-body">
                      <div className="col-md-12">
                        <div className="load-img">
                            Uploading...
                            <div className="loader"></div>
                        </div>  
                      </div>  
                  </div>  
                }
                </div>
                {!isLoading &&
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={()=>{
                    CreatePhotoStnIn()
                  }}>Save</button>
                  <button type="button" className="btn btn-secondary" onClick={()=>{
                    setcsss('modal fade')
                  }}>Cancel</button>
                </div>
                }
              </div>
            </div>
          </div>}
        </>
    )
}
export default Header;

