import React from 'react';
import Home from './Home';
import ReactDOM from "react-dom";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Login from './Login';
import StoryEdit from './StoryEdit';
import PendingReview from './PendingReview';
import Flagged from './Flagged';
import PublishedStory from './PublishedStory'
import Mystories from './Mystories'
import Logout from './Logout'
import PublishedStoryEng from './PublishedStoryEng'
import StoryPreview from './StoryPreview'
import AllPictures from './AllPictures'
import EditPicture from './EditPicture'
import AddNewPicture from './AddNewPicture'
import PendingPictures from './PendingPictures'
import CompletedPictures from './CompletedPictures'
import AddVideo from './AddVideo'
import Test from './Test';
import AllVideo from './AllVideo'
import AllNews from './AllNews'
import JustList from './JustList'
import EditJust from './EditJust'
import EditVideo from './EditVideo'
import PreviewVideo from './PreviewVideo'
import EditNewsStn from './EditNewsStn'
import EditStnVideo from './EditStnVideo'
import AllVideoStn from './AllVideoStn'
import MultiMedia from './MultiMedia'
import AllPhotosStn from './AllPhotosStn';
import PhotoEditStn from './PhotoEditStn';
import MyNewsBasket from './MyNewsBasket';
import MyPhotosBasket from './MyPhotosBasket';
import MyVideosBasket from './MyVideosBasket';
import AllHindiNews from './AllHindiNews'
import AllEnglishNews from './AllEnglishNews'
import SocialMedia from './SocialMedia';
import PrevStnVideo from './PrevStnVideo';
import MultiMediaVideos from './MultiMediaVideos'
import BusinessVideos from './BusinessVideos'
import EntertainmentVideos from './EntertainmentVideos'
import SportsVideos from './SportsVideos';
import ScienceTechVideos from './ScienceTechVideos';
import HindiVideos from './HindiVideos';
import EnglishVideos from './EnglishVideos'
import SocialMediaEditor from './SocialMediaEditor';
import EditStnSMVideo from './EditStnSMVideo';
import EditSM from './EditSM';


const App = () =>{
  return(
    <>
    <BrowserRouter>
    <Switch>
    <Route exact path="/" component={Home}/>
    <Route exact path="/test" component={Test}/>
    <Route exact path="/stories-edit/:editId" component={StoryEdit}/>
    <Route exact path="/just-edit/:editId" component={EditJust}/>
    <Route exact path="/edit-video/:editId" component={EditVideo}/>
    <Route exact path="/preview-video/:editId" component={PreviewVideo}/>


    <Route exact path="/my-news" component={MyNewsBasket}/>
    <Route exact path="/all-news" component={AllNews}/>
    <Route exact path="/all-english-news" component={AllEnglishNews}/>

    <Route exact path="/all-hindi-news" component={AllHindiNews}/>

    <Route exact path="/edit-stn-news/:editId" component={EditNewsStn}/>


    <Route exact path="/my-photos" component={MyPhotosBasket}/>
    <Route exact path="/all-photos-stn" component={AllPhotosStn}/>
    <Route exact path="/edit-stn-photos/:editId" component={PhotoEditStn}/>
    
    <Route exact path="/my-videos" component={MyVideosBasket}/>
    <Route exact path="/all-video-stn" component={AllVideoStn}/>
    <Route exact path="/socialmedia" component={SocialMedia}/>
    <Route exact path="/socialmediaeditor" component ={SocialMediaEditor} />
    <Route exact path="/multimedia" component={MultiMedia}/>
    <Route exact path="/multimedia-videos" component={MultiMediaVideos}/>
    <Route exact path="/business-videos" component={BusinessVideos}/>
    <Route exact path="/entertainment-videos" component={EntertainmentVideos}/>
    <Route exact path="/sportsvideos" component={SportsVideos}/>
    <Route exact path="/hindivideos" component={HindiVideos}/>
    <Route exact path="/englishvideos" component={EnglishVideos}/>
    <Route exact path="/science-techvideos" component={ScienceTechVideos}/>
    <Route exact path="/edit-stn-video/:editId" component={EditStnVideo}/>
    <Route exact path="/edit-smeditor/:editId" component={EditStnSMVideo} />
    <Route exact path="/edit-sm/:editId" component={EditSM} />
    <Route exact path="/preview-stn-video/:editId" component={PrevStnVideo} />

    <Route exact path="/login" component={Login}/>
    <Route exact path="/all-videos" component={AllVideo}/>
    <Route exact path="/all-news" component={AllNews}/>
    <Route exact path="/all-pictures" component={AllPictures}/>
    <Route exact path="/just-in-list" component={JustList}/>
    <Route exact path="/completed-pictures" component={CompletedPictures}/>
    <Route exact path="/pending-pictures" component={PendingPictures}/>
    <Route exact path="/add-new" component={AddNewPicture}/>
    <Route exact path="/edit-pictures/:editId" component={EditPicture}/>
    <Route exact path="/logout" component={Logout}/>
    <Route exact path="/published-english" component={PublishedStoryEng}/>
    <Route exact path="/mystories" component={Mystories} />
    <Route exact path="/flagged" component={Flagged} />
    <Route exact path="/pending" component={PendingReview}/>
    <Route exact path="/published" component={PublishedStory}/>
    <Route exact path="/stories-preview/:editId" component={StoryPreview}/>
    <Route exact path="/stories-edit/:editId/:storyId" component={StoryEdit}/>
    <Route exact path="/add-video"  component={AddVideo}/>
    <Redirect to="/" />
    </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
