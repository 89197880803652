import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import Button from 'react-bootstrap/Button';
import API_URL from './ApiData'
import { useHistory, NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import copy from "copy-to-clipboard"
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Modal from 'react-bootstrap/Modal';
const SportsVideos = () => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageList, setPageList] = useState([1,2,3]);
    const [lastPage, setLastPage] = useState(10);
    const [nextBtnStatus, setNextBtnStatus]=useState(false)
    const [prevBtnStatus, setPrevBtnStatus]=useState(false)
    const inputRef = useRef(null)
    const [markStatus, setMarkStatus]=useState(false)
    const history = useHistory()
    const swiperRef = useRef(null);
    const departmentsData = [
      { id: 1, slug: "social-media", short_name: "SM", name: "Social Media" },
      { id: 2, slug: "Multimedia", short_name: "MM", name: "Multimedia" },
      { id: 3, slug: "hindi-desk", short_name: "HIN", name: "Hindi Desk" },
      { id: 4, slug: "english-desk", short_name: "ENG", name: "English Desk" },
      { id: 5, slug: "business", short_name: "BUS", name: "Business" },
      { id: 6, slug: "entertainment", short_name: "ENT", name: "Entertainment" },
      { id: 7, slug: "sports", short_name: "SPO", name: "Sports" },
      { id: 8, slug: "science-tech", short_name: "ST", name: "Science & Tech" },
    ];
    const handleChecked = (event) => {
      setMarkStatus(event.target.checked);
    }
    const getCurrentTimeInIST = () => {
        const now = new Date();
        // Calculate IST by adding 5 hours and 30 minutes (in milliseconds)
        const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
        // Format the date to 'YYYY-MM-DD HH:mm:ss'
        const formattedDate = istTime.toISOString().replace('T', ' ').substring(0, 19);
        return `${formattedDate} +05:30`; // Append the IST offset
    };
    const handleNextPage = () => {
        if(nextBtnStatus){
          setCurrentPage(currentPage + 1);
          let newArry = pageList;
          let newAddArray = pageList[pageList.length-1]+1;
          // if(currentPage > 2){
            let newRemoveArray = pageList[0];
          //
          // }
          newArry.shift(newRemoveArray);
          newArry.push(newAddArray);
            setPageList(newArry);
            }
      }
        const [popData, setPopData] =useState()
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = (data) => {
            setShow(true)
            setPopData(data)
            console.log('vikasP', popData)
        }
          
         
      const handlePrevPage = () => {
      if(currentPage > 1){
        setCurrentPage(currentPage - 1);
        // setCurrentPage(currentPage + 1);
        let newArry = pageList;
        let newAddArray = pageList[pageList.length-1];
        let newRemoveArray = pageList[0]-1;
          newArry.unshift(newRemoveArray);
        newArry.pop(newAddArray);
      setPageList(newArry);
      }
      }
    
    
      const handleStartPage = () => {
      // if(requestFlag){
        setCurrentPage(1);
        let lastArrtty = [1, 2, 3]
        setPageList(lastArrtty);
      //  }
      }
    const handleChange = event=>{
        const {name, value} =event.target;
        setInputSData({ ...inputSData, [name]: value })
        setCurrentPage(1)
    
      }
      const [inputSData, setInputSData] = useState({
        title: ''
      })
    const fetchData = (page, ln)=>{
        setLoading(true);
        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
        };

        // Fetch data from the API when the component mounts
        let apiUrl = `https://stingers.ianswire.in/api/videos/?page=${currentPage}&departments=7&query=`
        if(inputSData.title){
            apiUrl = `${apiUrl}${inputSData.title}`
          }
        axios.get(apiUrl, { headers })
            .then(response => {
                setNewsData(response.data.results);
                if(response.data.next){
                    setNextBtnStatus(true)
                }else{
                    setNextBtnStatus(false)
                }             
                if(response.data.previous){
                    setPrevBtnStatus(true)
                }else{
                    setPrevBtnStatus(false)
                }
                setLastPage(response.data.count)
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }
    const handleRemoveImpLink = ()=>{

            axios({
              method: "get",
              url: `https://stingers.ianswire.in/api/reset_mark_important/`,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken :''}`,
                
              },
            
            })
              .then((res) => {
                fetchData(currentPage, localStorage.langg);
              })
              .catch((error) => {
              
                console.error("Error uploading image: ", error);
              });
    }
    const regex = /(<([^>]+)>)/gi;
    const [isAccept, setIsAccept] = useState(false)
    const [isload, setIsload] = useState(false)
    const [imgArry, setImgAry] = useState([]);
    const handleSubmit =  (type) => {
        // e.preventDefault();
        setIsload(true)
         if(!popData.title){
           alert('Title can not be blank.');
           setIsload(false)
           return false;
         }else if(!popData.description){
           alert('Description can not be blank.');
           setIsload(false)
           return false;
         }
     const NewAPArry = imgArry.map(video => video.id);
     
         let tempdata = {
           id: popData.id,
           title: popData.title,
           description: popData.description,
           video_ids: NewAPArry,
           videos:imgArry,
           event_type:popData.event_type,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: type== 'hold' ? 'on_hold' : type== 'pending' ? 'pending': type== 'submitted' ? 'submitted' :'',
           language: popData.language,
           mark_important:popData.mark_important,
           remarks: popData.remarks,
           slug:popData.slug,
           updated_at: getCurrentTimeInIST(),
         }
         let tempAccptdata = {
           id: popData.id,
           title: popData.title,
           description: popData.description,
           video_ids: NewAPArry,
           videos:imgArry,
           event_type:popData.event_type,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: 'approved',
           language: popData.language,
           accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
           remarks: popData.remarks,
           mark_important:popData.mark_important,
           slug:popData.slug,
           updated_at: getCurrentTimeInIST(),
         }
         let tempRejectdata = {
           id: popData.id,
           title: popData.title,
           description: popData.description,
           video_ids: NewAPArry,
           videos:imgArry,
           event_type:popData.event_type,
           stringers: popData.stringers && popData.stringers.length > 0 ? [parseInt(popData.stringers)]: [],
           status: 'rejected',
           mark_important:popData.mark_important,
           language: popData.language,
           slug:popData.slug,
           accepted_by: localStorage && localStorage.length > 0 && localStorage.userName ? localStorage.userName : '',
           remarks: popData.remarks,
           updated_at: getCurrentTimeInIST(),
         }  
         axios({
           method: "put",
           url: `${API_URL.CREATE_VIDEO}${popData.id}/`,
           data: type && type == 'approved' ? tempAccptdata : type == 2 ? tempRejectdata : tempdata,
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
           },
         }).then((response) => {
           if (response.status === 200) {
             console.log('Success:', response.data);
             setIsload(false)
             setShow(false)
             fetchData(currentPage, localStorage.langg);

            //  if(localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='All content' && type !='pending'){
            //      history.push('/all-video-stn'); // Redirect to the home page on success
            //  }else{
            //    if(type !='pending'){
            //      history.push('/my-videos'); // Redirect to the home page on success
            //    }
     
            //  }
           // history.push('/all-video-stn'); // Redirect to the home page on success
           }
         })
       }
       const SubmitBtn =(deptt2)=>{
             //alert(deptt2);
            //  try {
            //   const response = axios.put(`${API_URL.JUST_UPDATE}${editId}/update/`, inpustJust, {
            //     headers: {
            //       'Content-Type': 'application/json',
            //       Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
            //     },
            //   });
            //   if (response.status === 200) {
            //     console.log('Success:', response.data);
            //     history.push('/just-in-list'); // Redirect to the home page on success
            //   }
            // } catch (error) {
            //   console.error('Error:', error);
            // }
            //  return false;
       }

       const [reFresh, setReFresh] = useState(false)
       const copyToText = async () => {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = popData.description
     
        // Remove most HTML tags except for <p>
        let copyt = ''
     
        // Replace specific <p> tags with double line breaks
        //copy = copy.replace(/<p><\/p>/g, '\n\n');
     
        // Keep specific <p> tags at the bottom
        const specificTags = tempDiv.querySelectorAll('p')
        specificTags.forEach((tag) => {
           copyt += '\n\n' + tag.innerHTML;
        });
     
        // Combine title, short_desc, and modified content
        let final_copy = '';
        //if(final_copy){
           final_copy = `${popData.title}\n\n ${copyt}`
           final_copy=final_copy.replace("<br>","\n")
           final_copy=final_copy.replace('<br>','\n')
           final_copy = final_copy.replace(/&nbsp;/g, ' ')
           final_copy=final_copy.replace('<br data-mce-bogus="1">','')
           copy(final_copy);
           //handleSubmit(popData.title, popData.id)
        //}
        // Copy the final content to the clipboard
        //await navigator.clipboard.writeText(final_copy);
        }
        const handleMouseEnter = () => {
            if (swiperRef.current && swiperRef.current.swiper) {
              swiperRef.current.swiper.autoplay.stop();
            }
          };
        
          const handleMouseLeave = () => {
            if (swiperRef.current && swiperRef.current.swiper) {
              swiperRef.current.swiper.autoplay.start();
            }
          };
        const downloadToText = async () => {
           const tempDiv = document.createElement('div');
           tempDiv.innerHTML = popData.description;
     
           // Remove most HTML tags except for <p>
           let copyt = '';
     
           // Replace specific <p> tags with double line breaks
           //copy = copy.replace(/<p><\/p>/g, '\n\n');
     
           // Keep specific <p> tags at the bottom
           const specificTags = tempDiv.querySelectorAll('p');
           specificTags.forEach((tag) => {
              copyt += '\n\n' + tag.innerHTML;
           });
     
           // Combine title, short_desc, and modified content
           let final_copy = `${popData.title}\n\n ${copyt}`;
           final_copy=final_copy.replace('<br>','\n')
           final_copy = final_copy.replace(/&nbsp;/g, ' ')
           final_copy=final_copy.replace('<br data-mce-bogus="1">','')
           // Copy the final content to the clipboard
           copy(final_copy);
           const filename = popData.id+".txt"; // Replace with the desired file name
     
           const blob = new Blob([final_copy], { type: 'text/plain' });
           const url = URL.createObjectURL(blob);
     
           const a = document.createElement('a');
           a.href = url;
           a.download = filename;
     
           document.body.appendChild(a);
           a.click();
     
           window.URL.revokeObjectURL(url);
           document.body.removeChild(a);
          // handleSubmit(collectionData.title, collectionData.id)
        }
        const editRedir=(link)=>{
          history.push(link);
        } 
        const [refreshpage, setRefreshPage] = useState(false);
        const submitAccept=(id)=>{
          setIsload(true);
          let tempAccptdata = {

            SPT:localStorage && localStorage.langg && localStorage.langg == 'SPT' ? localStorage.userName : '',
            spt_updated_at:localStorage && localStorage.langg && localStorage.langg == 'SPT' ? getCurrentTimeInIST() : '',
          }
          axios({
            method: "put",
            url: `${API_URL.CREATE_VIDEO}${id}/`,
            data: tempAccptdata,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
            },
          }).then((response) => {
            if (response.status === 200) {
              console.log('Success:', response.data);
              setIsload(false)
              setRefreshPage(!refreshpage)
                  history.push('/sportsvideos'); // Redirect to the home page on success
            }
          })
        } 
          const stripHtmlWithNewlines = (html) => {
            if (!html) return "";
            
            // Convert <p> to newlines before stripping tags
            const formattedHtml = html.replace(/<\/p>/gi, "\n\n").replace(/<br\s*\/?>/gi, "\n");
            
            // Use DOMParser to strip remaining tags
            const doc = new DOMParser().parseFromString(formattedHtml, "text/html");
            return doc.body.textContent || "";
          };
        
          const handleCopy = (newsItem) => {
            const cleanDescription = stripHtmlWithNewlines(newsItem.description);
            const formattedText = `${newsItem.title}\n\n${cleanDescription}\n\nSource: ${newsItem.created_by}\n\Path: Y:/${newsItem.slug}`;
        
            copy(formattedText)
              .then(() => {
                // alert("Copied to clipboard!") history.push('/all-video-stn'); // ✅ Correct redirection for React Router v5
              })
              .catch(err => console.error("Failed to copy: ", err));
          };             
    useEffect(() => {
        // Set up headers
        if(localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg != 'SPT'){
            history.push('/my-videos');
        }
        if(localStorage && localStorage.length > 0 && localStorage.publishOpt && localStorage.publishOpt == 'publish'){
            setIsAccept(true)
          }
          setShow(false)
          fetchData(currentPage, localStorage.langg);

    }, [currentPage,refreshpage]);
    return (
        <>
    <div className="wrapper">
        <Header />
            <div className="content-wrapper">
                <section className="section dashboard">
                    <div className="row">
                        <div className="col-lg-12">
                        
                            <div className="row">
                            {  !loading && 
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                        <div className="row stn-search-hed">
                                            <div className="col-lg-6 d-flex align-items-center ev-stn">
                                                <h5 className="card-title"><span>All Videos</span> <img className="nav-i-icon" src="/assets/icons/stn-all-video-b.png"/></h5>
                                            </div>
                                            
                                            <div className="col-lg-4">
                                                <div className="search-form-allsnt">
                                                    <form onSubmit={()=>{fetchData(1,'')}}>
                                                        <input type="search" ref={inputRef} placeholder="Search...." name="title" value={inputSData.title} onChange={handleChange}/>
                                                        <span className="input-group-text border-0" onClick={()=>{
                                                fetchData(1,'')
                                                }}><i className="fas fa-search" aria-hidden="true"></i></span>
                                                    </form>
                                                </div>
                                            </div>
                                            {  localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='All content' &&
                                            <div className="col-lg-2 align-end">
                                                <div className="search-form-allsnt">
                                                <button className="btn btn-reset-mark" type="button" onClick={handleRemoveImpLink}>Reset Important <i className="fas fa-undo"></i></button>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div id="no-more-tables">                                        
                                        <table className="col-md-12 table table-bordered table-condensed cf">
                                                <thead className="cf">
                                                   <tr>
                                                        <th>ID</th>
                                                        <th className="col-md-5">Heading</th>
                                                        {/* <th className="numeric">Created by</th>
                                                        <th className="numeric">Approved by</th> */}
                                                        {/* <th className="numeric">Accepted by</th> */}
                                                        <th className="numeric col-md-4">Related Info</th>
                                                        <th className="numeric ">Assigned to</th>
                                                        <th className="numeric ">Status</th>
                                                        <th className="numeric col-md-3">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newsData.map((newsItem) => (
                                                        <tr key={newsItem.id}  className={newsItem.mark_important ? 'trmark-imp' : ''}>
                                                          
                                                        <td data-title="ID">{newsItem.id}</td>
                                                        <td data-title="Heading"><strong>{newsItem.title}</strong>
                                                        <p dangerouslySetInnerHTML={{ __html:  newsItem.description.substr(0, 300)
                                                          .replace('<p><br></p>','')
                                                          .replace('<p><br></p>', '')
                                                          .replace('<p><br></p>', '')
                                                          .replace('<p><br></p>', '')
                                                          .replace('<p><br></p>', '')
                                                          .replace('<p><br></p>', '')
                                                           }}/>
                                                        <p>Z:/{newsItem.slug}</p>

                                                        </td>
                                                        {/* <td data-title="Created by" className="numeric">{newsItem.created_by}</td> */}
                                                        
                                                        {/* <td data-title="Approved by" className="numeric">{newsItem.accepted_by}</td> */}
                                                        {/* <td data-title="Accepted by" className="numeric">{newsItem.accepted_by}</td> */}
                                                        <td data-title="Created & Updated at" className="numeric">
                                                        <table className='data-table-inline'>
                                                            <tr>
                                                              <td><strong>Created by</strong> </td>
                                                              <td> {newsItem.created_by}</td>
                                                            </tr>
                                                            <tr>
                                                              <td><strong>Accepted by</strong></td>
                                                              <td>{newsItem.accepted_by}</td>                                                                  
                                                            </tr>
                                                            <tr>
                                                              <td><strong>Created at:</strong></td>
                                                              <td>{new Date(newsItem.created_at).toLocaleString()}</td>
                                                            </tr>
                                                            <tr>
                                                              <td><strong>Updated at:</strong></td>
                                                              <td>{new Date(newsItem.updated_at).toLocaleString()}</td>
                                                            </tr>
                                                          </table>
                                                        </td>
                                                        <td data-title="Assigned to" className="numeric popup-container">
                                                         
                                                              {newsItem.departments.map((deptId) => {
                                                                            // Find department by ID
                                                                            const department = departmentsData.find((d) => d.id === deptId);
                                                                            if (!department) return null;

                                                                            // Check if the department exists in API response
                                                                            const departmentField = newsItem[department.short_name]; // Example: item["SM"], item["MM"]
                                                                            const isAvailable = departmentField !== null; // If not null, it's available

                                                                            const colorClass = isAvailable ? "bg-green-500" : "bg-red-500"; // Green if not null, Red if null

                                                                            return (
                                                                              <span
                                                                                key={deptId}
                                                                                className={`${colorClass}  abtn`}
                                                                              >
                                                                                {department.short_name}
                                                                              </span>
                                                                            );
                                                                          })}

                                                            <div className="popup">
                                                              <table className="border-collapse border border-gray-300 w-full">
                                                                <thead>
                                                                  <tr className="bg-gray-200">
                                                                    <th className="border border-gray-300 px-4 py-2">Department</th>
                                                                    <th className="border border-gray-300 px-4 py-2">Value</th>
                                                                    <th className="border border-gray-300 px-4 py-2">Updated At</th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {newsItem.departments.map((deptId) => {
                                                                    // Find department by ID
                                                                    const department = departmentsData.find((d) => d.id === deptId);
                                                                    if (!department) return null;

                                                                    // Extract values from newsItem dynamically
                                                                    const departmentValue = newsItem[department.short_name]; // Example: item["SM"]
                                                                    const updatedAtKey = department.short_name.toLowerCase() + '_updated_at';
                                                                    const updatedAt = newsItem[updatedAtKey];

                                                                    return (
                                                                      <tr key={deptId} className="border border-gray-300">
                                                                        <td className="border border-gray-300 px-4 py-2">{department.short_name}</td>
                                                                        <td className={`border border-gray-300 px-4 py-2 ${departmentValue !== null ? 'bg-green-500' : 'bg-red-500'}`}>
                                                                          {departmentValue !== null ? departmentValue : ''}
                                                                        </td>
                                                                        <td className="border border-gray-300 px-4 py-2">
                                                                          {updatedAt ? new Date(updatedAt).toLocaleString() : ''}
                                                                        </td>
                                                                      </tr>
                                                                    );
                                                                  })}
                                                                </tbody>
                                                              </table>
                                                            </div>  
                                                            </td>
                                                        <td data-title="Status" className="numeric">
                                                            {newsItem.status ? (
                                                                <span className={`badge ${getStatusClass(newsItem.status)}`}>
                                                                    {newsItem.status =='on_hold' ? 'hold': newsItem.status}
                                                                    {newsItem.mark_important ? (
                                                                        <div className="spinner-grow text-danger" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    ) : ''}
                                                                </span>
                                                            ) : ''}
                                                        </td>
                                                        <td data-title="Action" className="numeric mm-action">
                                                            <span className="btn btn-sm btn-primary mx-1" onClick={()=>editRedir('/preview-stn-video/' + newsItem.id)
                                                  }>
                                                             <i className="fas fa-eye viewedit" aria-hidden="true"></i>
                                                            </span>
                                                            <button className="btn btn-sm btn-secondary" onClick={() => handleCopy(newsItem)}>Copy</button>
                                                            {
                                                              newsItem.SPT ?
                                                              (<button className='btn btn-warning'>Accepted</button>):
                                                              (<button className='btn btn-success' onClick={()=>submitAccept(newsItem.id)}>Accept</button>)
                                                            }
                                                            

                                                        </td>
                                                       
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            {/* Pagination (You can customize pagination functionality here) */}
                                            <div className="pagination">
                                                <ul>
                                                    {currentPage == 1  && <li onClick={handleStartPage} disabled={currentPage === 1}><NavLink className="prevnext disablelink" to="#">Previous</NavLink></li>}
                                                    {currentPage != 1  &&<li onClick={handlePrevPage} ><a href="#">Previous</a></li>}
                                                    {pageList.map((data, i)=>{
                                                    return(
                                                    <li key={i} onClick={()=>{
                                                    setCurrentPage(data)}}><a href="#" className={currentPage == data ? 'active' : ''}>{data}</a></li>
                                                        )
                                                    })}
                                                    <li onClick={handleNextPage}><a href="#" className="prevnext">Next</a></li>
                                                </ul>                                            							
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            }
                                {loading && 
                                <div className="card-body">
                                    <div className="load-img">
                                        Loading...
                                        <div className="loader"></div>
                                    </div>  
                                </div>  
                            }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
        </>
    );
};

// Helper function to determine badge color based on status
const getStatusClass = (status) => {
    switch (status) {
        case 'approved':
            return 'bg-success';
        case 'on_hold':
            return 'bg-warning';
        case 'rejected':
            return 'bg-danger';
        default:
            return 'pending_snt';
    }
};
const handleView = (id) => {
    // Replace this with actual view functionality
    console.log(`Viewing news item with ID: ${id}`);
};

// Function to handle edit button click
const handleEdit = (id) => {
    // Replace this with actual edit functionality
    console.log(`Editing news item with ID: ${id}`);
};

export default SportsVideos;
