import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {Helmet} from "react-helmet";
import { useHistory, NavLink } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown'; // Adjust according to your Multiselect library
import API_URL from './ApiData'
import {useParams} from 'react-router-dom';
import copy from "copy-to-clipboard"
import { format, parseISO } from 'date-fns'
const EditStnSMVideo = () => {
  const history = useHistory()
  const [isAccept, setIsAccept] = useState(false)
  const {editId} = useParams();
  const handleChange = event=>{
    const {name, value} =event.target;
    if(name && name=='slug'){
      if (value.length > 50) {
        alert("You can only enter up to 50 characters!");
        let tempValue= value.substr(0,50);
        //return false;
        setInputData({ ...inputData, [name]: tempValue });
      }else{
        setInputData({ ...inputData, [name]: value });
      }
    //  alert(value)
    }else{
      setInputData({ ...inputData, [name]: value });
    }
  }
  const handleDeptChange = event=>{
    const {name, value} =event.target;

      setInputData({ ...inputData, [name]: value });
   
  }
  const getCurrentTimeInIST = () => {
    const now = new Date();
    // Calculate IST by adding 5 hours and 30 minutes (in milliseconds)
    const istTime = new Date(now.getTime() + (5.5 * 60 * 60 * 1000));
    // Format the date to 'YYYY-MM-DD HH:mm:ss'
    const formattedDate = istTime.toISOString().replace('T', ' ').substring(0, 19);
    return `${formattedDate} +05:30`; // Append the IST offset
};
  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  }
  const [readOn, setReadOn] = useState(false)
  const [isVideoRow, setIsVideoRow] = useState(false)
  const [conData, setValue] = useState('');
  const [markStatus, setMarkStatus]=useState(false)
   const [departData, setdepartData] = useState();
   const departmentsData = [
    { id: 1, slug: "social-media", short_name: "SM", name: "Social Media" },
    { id: 2, slug: "Multimedia", short_name: "MM", name: "Multimedia" },
    { id: 3, slug: "hindi-desk", short_name: "HIN", name: "Hindi Desk" },
    { id: 4, slug: "english-desk", short_name: "ENG", name: "English Desk" },
    { id: 5, slug: "business", short_name: "BUS", name: "Business" },
    { id: 6, slug: "entertainment", short_name: "ENT", name: "Entertainment" },
    { id: 7, slug: "sports", short_name: "SPO", name: "Sports" },
    { id: 8, slug: "science-tech", short_name: "ST", name: "Science & Tech" },
  ];
// const defaultCheck = [    {id:1,
//   slug:'social-media',
//   name:'Social Media',
//   status:true,
//   description:'Social Media department'
// },
// {
//   id:2,
//   slug:'Multimedia',
//   name:'Multimedia',
//   status:false,
//   description:'Multimedia department'
// },
// {
//   id:3,
//   slug:'hindi-desk',
//   name:'Hindi Desk',
//   status:false,
//   description:'Hindi Desk'
// },
// {
//   id:4,
//   slug:'english-desk',
//   name:'English Desk',
//   status:false,
//   description:'English Desk'
// }];
const defaultCheck = [1,2,3,4];
  const handleChecked = (event) => {
    setMarkStatus(event.target.checked);
  };
  const [inputData, setInputData] = useState({
    sm_file_location: '',
  })
  const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
let remarT = [ { Name: "SM", status: false, username: "vikas"}, { Name: "MM", status: false, username: "vikas" }, { Name: "HIN", status: false, username: "vikas" }, { Name: "ENG", status: false, username: "vikas" }];
  const fetchData = async () => {
    setIsload(true) 
      axios(
         {method: "get",
          url: `${API_URL.CREATE_VIDEO}${editId}/`,
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`, // Ensure correct header
          },
        
        })
      .then((res) => {
          if(res && res.data){
            setInputData({
              sm_file_location: res.data.sm_file_location,
             
            })
            if(res.data.mark_important){
              setMarkStatus(true)
          }
          if(res && res.data && res.data.departments && res.data.departments.length > 0){
          
            let departmentIds = res.data.departments; // [3, 5]

            // Filter departmentsData to match only those with the given IDs
            const filteredDepartments = departmentsData.filter((dept) =>
              departmentIds.includes(dept.id)
            );
            setdepartData(filteredDepartments);
            setSelectedDepartments(res.data.departments); 
          }else{

            setSelectedDepartments(defaultCheck); 
          }
         
            setValue(res.data.sm_file_location)
            if(res.data.videos && res.data.videos.length > 0){
              setImgAry(res.data.videos)
            }
            // if(res.data.created_by && localStorage && localStorage.length > 0 && localStorage.userName && res.data.created_by != localStorage.userName){
            //   setIsAccept(true)
            // }
            if(res.data.remarks){
              setRemoveCont(res.data.remarks)
            }
            setIsload(false)
          }
      })
      //setIsload(false)
  }


  // Usage



  const handleCheckboxChange = (id) => {
    setSelectedDepartments((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((depId) => depId !== id) // Remove if exists
        : [...prevSelected, id] // Add if not exists
    );
  };
  const handleSubmit =  () => {
   // e.preventDefault();
   setIsload(true)
    if(!inputData.sm_file_location){
      alert('SM File Location can not be blank.');
      setIsload(false)
      return false;
    }

    let tempdata = {
      sm_file_location: conData,
    }
 
    axios({
      method: "put",
      url: `${API_URL.CREATE_VIDEO}${editId}/`,
      data:  tempdata,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.accessToken ? localStorage.accessToken : ''}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        console.log('Success:', response.data);
        setIsload(false)

        if(localStorage && localStorage.length > 0 &&  localStorage.langg && localStorage.langg=='SM EDIT'){
            history.push('/socialmediaeditor'); // Redirect to the home page on success
      
          
        }
      // history.push('/all-video-stn'); // Redirect to the home page on success
      }
    })
  }
 
const [strinData, setStringData] = useState()
const [optionsTY, setOptionsTY] =useState()
const inputSData=(event)=>{
  event.preventDefault()
  setSearch(event.target.value);
} 
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)

    const getStringerData = () =>{
            let imSearchAPI = `https://stingers.ianswire.in/api/stringers/?stringer_type=video_service`;
            if(inputSearch){
                imSearchAPI = imSearchAPI+`&search=${inputSearch}`
            }
            axios.get(
            imSearchAPI,
            {
                headers: {
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                },
            }
            ).then((res) => {
            const allStrngData =
                res.data.results &&
                res.data.results.length > 0 &&
                res.data.results.map((item) => {
                return {
                account_no: item.account_no,
                district : item.district,
                email : item.email,
                id : parseInt(item.id),
                ifsc_code : item.ifsc_code,
                name: item.name,
                number : item.number,
                state: item.state,
                uniqueID : item.uniqueID
                    
                };
                });
            if(res && res.data.results && res.data.results && res.data.results.length > 0 ){
            setStringData(allStrngData);
            const optionsvvTY = allStrngData.map(person => ({
                value: person.id,
                label: person.name
              }));
              setOptionsTY(optionsvvTY);
            // console.log('vbb',imgSearchD);
            }else{
              setStringData()
            }
            });
        if(!inputSearch){
          fetchData()
        }
    }
  const [isload, setIsload] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [msg, setMsg]= useState('')
  const [imgArry, setImgAry] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileChange = (e) => {
    if(e && e.target && e.target.files[0]){
      if(e.target.files[0].type != "video/mp4" && e.target.files[0].type != "video/mov"){
        alert(`${e.target.files[0].type}  not be allowed.`)
        e.target.value = null;
      }
    }
    const files = e.target.files;
    setSelectedFiles([...files]); // Use the spread operator to create a new array
    
  };

    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadVideo = (e) => {
        e.preventDefault();
        const formData = new FormData();
        // Append each selected file as 'video' to the formData
        selectedFiles.forEach((file) => {
            formData.append('video', file);
        });

        setIsLoading(true);

        axios({
            method: "post",
            url: `${API_URL.CREATE_VIDEO}upload-video/`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${localStorage.accessToken || ''}`,
            },
            onUploadProgress: (progressEvent) => {
                const total = progressEvent.total;
                const loaded = progressEvent.loaded;

                // Calculate and update progress
                const percentage = Math.round((loaded * 100) / total);
                setUploadProgress(percentage); // Update state
                console.log(`Upload Progress: ${percentage}%`);
            },
        })
        .then((res) => {
            if (res?.data?.uploaded_videos?.length > 0) {
                const newVideos = res.data.uploaded_videos.map((item) => ({
                    id: item.id || "",
                    video: item.video_url || "",  // Updated to match video URL field
                }));
                // Merge new videos with existing array or set new array
                setImgAry(imgArry && imgArry.length > 0 ? imgArry.concat(newVideos) : newVideos);
                setIsLoading(false);
            }
        })
        .catch((error) => {
            setIsLoading(false);
            console.error("Error uploading video: ", error);
        });

        // Clear the form input fields and selected files
        setSelectedFiles([editId]);
};
const [removeCont, setRemoveCont] = useState('')
const removeImg = (id)=>{
 // alert(id)
  const newImgArry = imgArry.filter(item => item.id !== id)
  if(!removeCont){
    setRemoveCont('Deleted id -'+id)
  }else{
    setRemoveCont(removeCont + ', '+id)
  }
  
  setImgAry(newImgArry)
}
const [selectedDepartments, setSelectedDepartments] = useState([]);

  useEffect(() => {
    // Fetch tags
    // if(localStorage && localStorage.length > 0 && (localStorage.videoOpt || localStorage.editor)){
    //   setReadOn(false)
    // }
    if(localStorage && localStorage.length > 0 && localStorage.publishOpt && localStorage.publishOpt == 'publish'){
      setIsAccept(true)
    }
    
    getStringerData()
 
  }, [editId, msg,inputSearch]);
  return (
    <>
<Helmet>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <link rel="icon" href="icon-new.ico" type="image/x-icon" />
  <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
  <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
  <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
</Helmet>
  <div className="wrapper">
  <Header />
  <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-10">
                <h1 className="m-0">Edit Stn Video</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="col-lg-12">
            {!isload &&
              <div className="card card-outline card-primary">
                <div className="card-body">
                  <form>
                    <div className="form-row">
 
                      <div className="form-group col-md-12">
                        <label htmlFor="inputHeading">SM File Location: *</label>
                      
                        <ReactQuill className="form-control form-control-user editShortDesc" 
                                theme="snow" 
                                type="text"
                                name="content" 
                                value={conData} 
                                onChange={setValue}
                              
                                />
                      </div>
                    </div>


    
                    <div className="col-md-12 text-center mt-4 pub-btn-fixed">
                  
                  { localStorage.langg == 'SM EDIT' && <button className="btn btn-primary" type="button" onClick={() => handleSubmit()}>Save</button>}

        
                   
                      </div>   
                  </form>
                </div>
              </div>
              }
              {isload &&
                <div className="col-md-12">
                    <div className="load-img">
                      Loading...
                      <div className="loader"></div>
                    </div>
                </div>
              }
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  </>
  );
};

export default EditStnSMVideo;
